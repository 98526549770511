import React, { useRef, useState } from 'react';
import { useSx, SxProp } from 'dripsy';
import { useHover } from 'react-native-web-hooks';

interface TextAreaProps {
  value: string;
  onChange: (text: string) => void;
  label: string;
  placeholder?: string;
  disabled?: boolean;
  labelSx?: SxProp;
  labelClassName?: string;
}

const TextArea: React.FC<TextAreaProps> = ({
  value,
  onChange,
  label,
  placeholder,
  disabled = false,
  labelSx,
  labelClassName,
}) => {
  const [isFocused, setIsFocused] = useState(false);

  const areaRef = useRef(null);
  const isHovered = useHover(areaRef);

  const sx = useSx();

  const handleFocus = () => setIsFocused(true);
  const handleBlur = () => setIsFocused(false);

  const containerStyles: SxProp = {
    display: 'flex',
    alignSelf: 'stretch',
    flexDirection: 'column',
    alignItems: 'flex-start',
    width: '100%',
  };

  const textAreaStyles: SxProp = {
    fontFamily: 'inherit',
    height: '124px',
    resize: 'none',
    padding: '12px',
    borderRadius: '6px',
    borderWidth: '1px',
    borderColor: isFocused
      ? 'primary600'
      : isHovered
      ? 'primary600'
      : 'neutral500',
    backgroundColor: 'neutral100',
    color: disabled ? 'neutral700' : 'gray600',
    width: '100%',
    boxShadow: isFocused ? '0px 0px 0px 3px #B2D5FF' : 'none',
    outline: 'none',
    cursor: disabled ? 'not-allowed' : 'text',
  };

  const textAreaClassName = `textarea-${label
    .replace(/\s+/g, '-')
    .toLowerCase()}`;

  const placeholderColor = sx({
    color: disabled ? 'neutral700' : 'gray50',
  }).color;

  const placeholderStyles = `
    .${textAreaClassName}::placeholder {
      color: ${placeholderColor};
    }
  `;

  const labelStyles: SxProp = {
    fontSize: '14px',
    fontWeight: '500',
    lineHeight: '20px',
    ...sx({ color: disabled ? 'neutral700' : 'gray100' }),
    marginBottom: '6px',
    ...labelSx,
  };

  return (
    <div style={sx(containerStyles)}>
      {label && (
        <label style={sx(labelStyles)} className={labelClassName}>
          {label}
        </label>
      )}
      <style>{placeholderStyles}</style>
      <textarea
        className={textAreaClassName}
        ref={areaRef}
        style={sx(textAreaStyles)}
        value={value}
        onChange={(e) => onChange(e.target.value)}
        onFocus={handleFocus}
        onBlur={handleBlur}
        placeholder={placeholder}
      />
    </div>
  );
};

export default TextArea;
