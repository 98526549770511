import React from 'react';
import Splash from '../../components/layout/Splash';
import { useNavigate, useParams } from 'react-router-dom';
import useAxiosPrivate from '../../hooks/useAxiosPrivate';
import axios from '../../api/axios';
import jwt_decode from 'jwt-decode';
import { useDispatch } from 'react-redux';
import { setAuth } from '../../state/auth';
import { showToast } from '../../aurora/components/Toast/Toast';
import SetPasswordForm from './components/SetPasswordForm';

const RegisterPage = () => {
  const REGISTER_URL = '/api/v1/users/register';
  const LOGIN_URL = '/auth/login';

  const { token } = useParams();
  const axiosPrivate = useAxiosPrivate();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const displayError = (title: string, text: string) => {
    showToast({ type: 'error', title, text });
  };

  const handleRegistrationError = (err: any) => {
    let errMsg: string;
    if (!err.response) {
      errMsg = 'Registration Failed: No server response';
    } else {
      switch (err.response.status) {
        case 400:
          errMsg =
            err.response.data === 'unable to create user'
              ? 'unable to create user'
              : err.response.data === 'Invite expired'
              ? 'Invite expired. Please use reset link again'
              : 'Invalid Data';
          break;
        case 409:
          errMsg = 'User Already Exists';
          break;
        default:
          errMsg = 'Registration Failed';
      }
    }
    displayError('Account Creation Error', errMsg);
  };

  const handleLoginError = (err: any) => {
    let errMsg: string;
    if (!err?.response) {
      errMsg = 'Login Failed: No server response';
    } else {
      switch (err.response.status) {
        case 400:
          errMsg = 'Missing Email or Password';
          break;
        case 401:
          errMsg = 'Unauthorized';
          break;
        default:
          errMsg = 'Login Failed';
      }
    }
    displayError('Login Error', errMsg);
  };

  const handleRegistrationAndLogin = async (
    token: string | undefined,
    password: string
  ) => {
    const sanitizedPassword = password.trim();
    const registerResponse = await axios.post(
      `${REGISTER_URL}/${token}`,
      JSON.stringify({ password: sanitizedPassword }),
      {
        headers: { 'Content-Type': 'application/json' },
        withCredentials: true,
      }
    );
    if (registerResponse.status === 201) {
      try {
        const response = await axiosPrivate.post(
          LOGIN_URL,
          JSON.stringify({
            email: registerResponse.data.email,
            password: sanitizedPassword,
          }),
          {
            headers: { 'Content-Type': 'application/json' },
            withCredentials: true,
          }
        );

        const { accessToken } = response.data;
        const { userInfo } = jwt_decode<any>(accessToken);

        dispatch(setAuth({ ...userInfo, accessToken }));
        navigate('/registration-confirmation', { replace: true });
      } catch (err: any) {
        handleLoginError(err);
      }
    }
  };

  return (
    <Splash
      card="You've been invited to..."
      subtitle={'Create a password to finish setting up your account.'}
      iconType="logo"
    >
      <SetPasswordForm
        token={token}
        submitPassword={handleRegistrationAndLogin}
        handleSubmitError={handleRegistrationError}
      />
    </Splash>
  );
};

export default RegisterPage;
