import React, { useState, useEffect } from 'react';
import {
  ShippingProfileEntity,
  ProfileType,
} from '../../../state/shippingProfile/types';
import TextInput from '../../../aurora/components/TextInput/TextInput';
import Select from '../../../aurora/components/Select/Select';
import TextArea from '../../../aurora/components/TextArea/TextArea';
import ShippingProfileFormSection from './ShippingProfileFormSection';
import ShippingProfileFormRow from './ShippingProfileFormRow';
import {
  samplingRateOptions,
  minTemperatureOptions,
  maxTemperatureOptions,
  steadyStateExcursionWindowOptions,
  cooldownExcursionWindowOptions,
  criticalExcursionLowOptions,
} from './ShippingProfileFormOptions';
import { useSelector } from 'react-redux';
import { isArtycAdmin, selectAuth } from '../../../state/auth';
import { RootState } from '../../../state/store';
import { Button } from '../../../aurora/components/Button/Button';
import { useSx } from 'dripsy';
import NoticeModal from '../../../components/NoticeModal/NoticeModal';

interface ShippingProfileFormProps {
  profile?: ShippingProfileEntity;
  selectedCompanyId?: string;
  onUpdateFormDataRef?: (newFormData: FormDataType) => void;
  onSave: (profileData: FormDataType) => Promise<void>;
  onDelete?: (profileData: FormDataType) => Promise<void>;
  updating: boolean;
}

export interface FormDataType {
  name: string;
  type: ProfileType;
  company: string;
  _id: string;
  samplingRateSec: number | null;
  minSteadyStateTempC: number | null;
  maxSteadyStateTempC: number | null;
  excursionWindowSec: number | null;
  cooldownTimeoutSec: number | null;
  minCriticalTempC: number | null;
  maxCriticalTempC: number | null;
  notes: string;
}

const ShippingProfileForm: React.FC<ShippingProfileFormProps> = ({
  profile,
  selectedCompanyId,
  onUpdateFormDataRef,
  onSave,
  onDelete,
  updating,
}) => {
  const auth = useSelector(selectAuth);

  let companyId: string = selectedCompanyId
    ? selectedCompanyId
    : auth!.companyId;

  let companyName = '';

  if (selectedCompanyId || auth) {
    const foundCompany = useSelector((state: RootState) =>
      state.companies.data.find((company) => company._id === companyId)
    );
    companyName = foundCompany?.companyName ?? '';
  }

  const generateFormData = (profile: ShippingProfileEntity | undefined) => ({
    _id: profile?._id ?? '',
    company: profile?.company ?? companyId,
    name: profile?.name ?? '',
    type: profile?.type ?? ProfileType.Custom,
    samplingRateSec: profile?.samplingRateSec ?? null,
    minSteadyStateTempC: profile?.minSteadyStateTempC ?? null,
    maxSteadyStateTempC: profile?.maxSteadyStateTempC ?? null,
    excursionWindowSec: profile?.excursionWindowSec ?? null,
    cooldownTimeoutSec: profile?.cooldownTimeoutSec ?? null,
    minCriticalTempC: profile?.minCriticalTempC ?? null,
    maxCriticalTempC: profile?.maxCriticalTempC ?? null,
    notes: profile?.notes ?? '',
  });

  const originalFormData = generateFormData(profile);
  const [formData, setFormData] = useState<FormDataType>(originalFormData);
  const [isFormValid, setIsFormValid] = useState(false);
  const [hasBeenEdited, setHasBeenEdited] = useState(false);
  const [showConfirmDelete, setShowConfirmDelete] = useState(false);

  const sx = useSx();

  useEffect(() => {
    setFormData(originalFormData);
  }, [profile]);

  useEffect(() => {
    if (onUpdateFormDataRef) {
      onUpdateFormDataRef(formData);
    }
  }, [formData, onUpdateFormDataRef]);

  const shallowEqual = (obj1: FormDataType, obj2: FormDataType) => {
    const keys1 = Object.keys(obj1) as Array<keyof FormDataType>;
    const keys2 = Object.keys(obj2) as Array<keyof FormDataType>;

    if (keys1.length !== keys2.length) {
      return false;
    }

    for (const key of keys1) {
      if (obj1[key] !== obj2[key]) {
        return false;
      }
    }
    return true;
  };

  const checkFormValidity = () => {
    const areStringsFilled = formData.name.trim() !== '';

    const areNumbersFilled =
      formData.samplingRateSec !== null &&
      formData.minSteadyStateTempC !== null &&
      formData.maxSteadyStateTempC !== null &&
      formData.excursionWindowSec !== null &&
      formData.cooldownTimeoutSec !== null;

    const areTempsValid =
      areNumbersFilled &&
      formData.minSteadyStateTempC! < formData.maxSteadyStateTempC!;

    const areCriticalTempsValid = () => {
      if (
        formData.minCriticalTempC === null ||
        formData.maxCriticalTempC === null
      ) {
        return formData.minCriticalTempC === formData.maxCriticalTempC;
      }
      return formData.minCriticalTempC < formData.maxCriticalTempC;
    };

    return (
      areStringsFilled &&
      areNumbersFilled &&
      areTempsValid &&
      areCriticalTempsValid()
    );
  };

  useEffect(() => {
    setHasBeenEdited(!shallowEqual(originalFormData, formData));

    const isValid = checkFormValidity();
    setIsFormValid(isValid);
  }, [formData]);

  const handleTextChange =
    (fieldName: keyof FormDataType) => (textValue: string) => {
      setFormData((prevState) => ({
        ...prevState,
        [fieldName]: textValue,
      }));
    };

  const handleSelectChange =
    (fieldName: keyof FormDataType) =>
    (selectedOption: { value: string | number; label: string }) => {
      setFormData((prevState) => ({
        ...prevState,
        [fieldName]: selectedOption.value,
      }));
    };

  const resetCriticalTempSection = () => {
    setFormData((prevState) => ({
      ...prevState,
      maxCriticalTempC: null,
      minCriticalTempC: null,
    }));
  };

  const onConfirmDelete = () => {
    setShowConfirmDelete(false);
    if (onDelete !== undefined) {
      onDelete(formData);
    }
  };

  const footerContainerStyle = sx({
    color: 'gray600',
    fontSize: 16,
    fontWeight: '600',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '24px',
    background: 'white',
    width: '100%',
    borderTopWidth: '1px',
    borderTopStyle: 'solid',
    borderTopColor: 'neutral500',
    position: 'fixed',
    bottom: 0,
    left: 0,
    right: 0,
    zIndex: 1000,
  });

  return (
    <div style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
      <div style={{ paddingBottom: '84px' }}>
        <ShippingProfileFormSection title={'Basics'} firstSection>
          <ShippingProfileFormRow>
            {isArtycAdmin(auth) && (
              <TextInput
                variant="form"
                placeholder={'Name'}
                label={'Company'}
                managedText={companyName}
                disabled={true}
              />
            )}
          </ShippingProfileFormRow>
          <ShippingProfileFormRow>
            <TextInput
              variant="form"
              placeholder={'Name'}
              label={'Profile Name'}
              managedText={formData.name}
              onChange={handleTextChange('name')}
              tooltip="Unique name for quick identificationand selection within the system."
            />
            <Select
              variant="form"
              label={'Sampling Rate'}
              placeholder={'Select'}
              options={samplingRateOptions}
              managedValue={formData.samplingRateSec}
              onChange={handleSelectChange('samplingRateSec')}
              tooltip="Frequency at which the temperature is recorded and monitored."
            />
          </ShippingProfileFormRow>
          <ShippingProfileFormRow>
            <Select
              variant="form"
              label={'Minimum Temp.'}
              placeholder={'Select'}
              options={minTemperatureOptions}
              managedValue={formData.minSteadyStateTempC}
              onChange={handleSelectChange('minSteadyStateTempC')}
              tooltip="Minimum safe temperature for this profile. Standard excursions are tracked based on this range."
            />
            <Select
              variant="form"
              label={'Maximum Temp.'}
              placeholder={'Select'}
              options={maxTemperatureOptions}
              managedValue={formData.maxSteadyStateTempC}
              onChange={handleSelectChange('maxSteadyStateTempC')}
              tooltip="Maximum safe temperature for this profile. Standard excursions are tracked based on this range."
            />
          </ShippingProfileFormRow>
        </ShippingProfileFormSection>
        <ShippingProfileFormSection title={'Steady State Excursion'}>
          <ShippingProfileFormRow>
            <Select
              variant="form"
              label={'Excursion Window'}
              placeholder={'Select'}
              options={steadyStateExcursionWindowOptions}
              managedValue={formData.excursionWindowSec}
              onChange={handleSelectChange('excursionWindowSec')}
              tooltip="Duration for which a temperature deviation from the safe range is permissible. Beyond this will trigger an event."
            />
          </ShippingProfileFormRow>
        </ShippingProfileFormSection>
        <ShippingProfileFormSection title={'Cooldown Excursion'}>
          <ShippingProfileFormRow>
            <Select
              variant="form"
              label={'Cooldown Timeout'}
              placeholder={'Select'}
              options={cooldownExcursionWindowOptions}
              managedValue={formData.cooldownTimeoutSec}
              onChange={handleSelectChange('cooldownTimeoutSec')}
              tooltip="Permitted duration for the device to reach the safe temperature range after activation. Beyond this will trigger an event."
            />
          </ShippingProfileFormRow>
        </ShippingProfileFormSection>
        <ShippingProfileFormSection
          title={'Critical Excursion'}
          toggleSwitch={{
            defaultValue:
              formData.minCriticalTempC !== null ||
              formData.maxCriticalTempC !== null,
            onSwitchOff: resetCriticalTempSection,
          }}
          tooltip="Switch on if your shipment requires immediate alerts for temperatures beyond critical thresholds."
        >
          <ShippingProfileFormRow>
            <Select
              variant="form"
              options={criticalExcursionLowOptions}
              label={'Critical Temp. Low'}
              placeholder={'Select'}
              managedValue={formData.minCriticalTempC}
              onChange={handleSelectChange('minCriticalTempC')}
              tooltip="Temperatures dropping below this threshold trigger an immediate event, indicating potential spoilage or damage."
            />
            <Select
              variant="form"
              options={criticalExcursionLowOptions}
              label={'Critical Temp. High'}
              placeholder={'Select'}
              managedValue={formData.maxCriticalTempC}
              onChange={handleSelectChange('maxCriticalTempC')}
              tooltip="Temperatures exceeding this threshold trigger an immediate event, indicating potential spoilage or damage."
            />
          </ShippingProfileFormRow>
        </ShippingProfileFormSection>
        <ShippingProfileFormSection
          title={'Additional Information'}
          lastSection
        >
          <TextArea
            label="Notes"
            value={formData.notes}
            onChange={handleTextChange('notes')}
            placeholder={'Add any additional info here'}
          />
        </ShippingProfileFormSection>
      </div>
      <div style={footerContainerStyle}>
        <div style={{ display: 'flex', gap: '16px', width: '100%' }}>
          {onDelete && (
            <>
              <Button
                variant="secondaryError"
                label="Delete"
                sx={{ flex: 1, color: 'red' }}
                onClick={() => setShowConfirmDelete(true)}
                loading={updating}
              />
              <NoticeModal
                title="Are you sure?"
                notice="This action cannot be undone."
                show={showConfirmDelete}
                onClose={() => setShowConfirmDelete(false)}
                buttons={
                  <>
                    <Button
                      variant="secondary"
                      label="Cancel"
                      onClick={() => setShowConfirmDelete(false)}
                    />
                    <Button
                      variant="secondaryError"
                      label="Delete"
                      onClick={onConfirmDelete}
                    />
                  </>
                }
              />
            </>
          )}
          <Button
            variant="primary"
            label="Save"
            sx={{ flex: 1 }}
            onClick={() => onSave(formData)}
            disabled={!isFormValid || !hasBeenEdited}
            loading={updating}
          />
        </div>
      </div>
    </div>
  );
};

export default ShippingProfileForm;
