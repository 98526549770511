export enum EventTypesEnum {
  TEMP_EXCURSION = 'TEMP_EXCURSION',
  LOW_BATTERY = 'LOW_BATTERY',
  LID_OPEN = 'LID_OPEN',
  DROP = 'DROP',
}

export enum EventStatusesEnum {
  NEW = 'NEW',
  REVIEWED = 'REVIEWED',
}

export interface EventEntity {
  _id: string;
  status: EventStatusesEnum;
  pid: string;
  deviceSerialNumber: string;
  eventType: EventTypesEnum;
  eventStart: string;
  eventEnd?: string;
  eventDetails: object;
  companyName: string;
  shipment: string;
  shipmentPid: string;
}

export type StatusFilter = EventStatusesEnum | 'ALL';

export interface EventFilters {
  search?: string;
  startDate?: string;
  endDate?: string;
  status?: StatusFilter;
  companyIds?: string[];
}

export const readableType: Record<EventTypesEnum, string> = {
  [EventTypesEnum.TEMP_EXCURSION]: 'Temperature Excursion',
  [EventTypesEnum.DROP]: 'Drop',
  [EventTypesEnum.LID_OPEN]: 'Lid Open',
  [EventTypesEnum.LOW_BATTERY]: 'Low Battery',
};

export type TemperatureExcursionDetails = {
  deviationStage?: DeviationStage;
  maxDeviation?: number;
  deviationTempC?: number;
};

export enum DeviationStage {
  INITIAL_COOLDOWN = 'initialCooldown',
  POST_COOLDOWN = 'postCooldown',
}
