export enum ProfileType {
  Standard = 'Standard',
  Custom = 'Custom',
}

export interface ShippingProfileEntity {
  _id: string;
  company: string;
  name: string;
  type: ProfileType;
  samplingRateSec: number;
  minSteadyStateTempC: number;
  maxSteadyStateTempC: number;
  excursionWindowSec: number;
  cooldownTimeoutSec: number;
  minCriticalTempC?: number;
  maxCriticalTempC?: number;
  notes?: string;
}
