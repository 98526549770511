import { makeTheme } from 'dripsy';

const rootFont = 'Inter, sans-serif';

const baseTheme = makeTheme({
  colors: {
    white: '#fff',
    neutral50: '#FAFBFC',
    neutral100: '#F8F9FB',
    neutral400: '#F5F5F5',
    neutral500: '#EFEFF1',
    neutral700: '#E5E5E7',
    neutral950: '#A5ACBA',
    gray50: '#858C95',
    gray100: '#596574',
    gray200: '#525D6A',
    gray300: '#4C5560',
    gray500: '#3F454D',
    gray600: '#383D43',
    gray700: '#323539',
    gray900: '#252525',
    gray950: '#181818',
    primary25: '#ECF5FF',
    primary50: '#D3E7FF',
    primary600: '#0a77ff',
    primary800: '#125DCB',
    primary900: '#1750B1',
    warning600: '#EEA23E',
    success600: '#2EBF43',
    error50: '#FFEFEE',
    error600: '#E33B32',
  },
  fontSizes: {
    $0: '13px',
    $1: '14px',
    $2: '15px',
    $3: '16px',
    $4: '18px',
    $5: '24px',
    $6: '32px',
    $7: '36px',
  },
  text: {
    heading: {
      s: {
        fontSize: '$5',
        lineHeight: '32px',
        fontWeight: '500',
        letterSpacing: '-1.5%',
      },
      m: {
        fontSize: '$6',
        lineHeight: '40px',
      },
      l: {
        fontSize: '$7',
        lineHeight: '44px',
      },
    },
    body: {
      xs: {
        fontSize: '$0',
        lineHeight: '18px',
      },
      s: {
        fontSize: '$1',
        lineHeight: '20px',
      },
      m: {
        fontSize: '$2',
        lineHeight: '22px',
      },
      l: {
        fontSize: '$3',
        lineHeight: '24px',
      },
      xl: {
        fontSize: '$4',
        lineHeight: '28px',
      },
    },
    breadCrumb: {
      fontWeight: '500',
      color: 'gray50',
    },
  },
  banner: {
    warning: {
      backgroundColor: 'warning600',
    },
    success: {
      backgroundColor: 'success600',
    },
  },
  buttons: {
    primary: {
      borderColor: 'transparent',
      backgroundColor: 'primary600',
      color: 'white',
      $hover: {
        backgroundColor: 'primary800',
        color: 'white',
      },
      $active: {
        backgroundColor: 'primary800',
        color: 'white',
      },
    },
    secondary: {
      borderColor: 'neutral700',
      backgroundColor: 'white',
      color: 'gray600',
      $hover: {
        backgroundColor: 'neutral400',
        color: 'gray600',
      },
      $active: {
        backgroundColor: 'neutral100',
        color: 'gray600',
      },
    },
    secondaryError: {
      borderColor: 'error600',
      backgroundColor: 'transparent',
      color: 'error600',
      $hover: {
        backgroundColor: 'error50',
        color: 'error600',
      },
      $active: {
        backgroundColor: 'transparent',
        color: 'gray600',
      },
    },
    secondaryNoBorder: {
      borderColor: 'transparent',
      backgroundColor: 'white',
      color: 'gray600',
      $hover: {
        backgroundColor: 'neutral400',
        color: 'gray600',
      },
      $active: {
        backgroundColor: 'neutral100',
        color: 'gray600',
      },
    },
    seeThrough: {
      borderColor: 'white',
      borderWidth: '1.5px',
      backgroundColor: 'transparent',
      color: 'white',
      $hover: {
        backgroundColor: 'rgba(255, 255, 255, 0.3)',
        color: 'white',
      },
      $active: {
        backgroundColor: 'neutral100',
        color: 'gray600',
      },
    },
    ghostPrimary: {
      borderColor: 'transparent',
      backgroundColor: 'transparent',
      color: 'primary600',
      $hover: {
        backgroundColor: 'transparent',
        color: 'primary800',
      },
      $active: {
        backgroundColor: 'transparent',
        color: 'primary800',
      },
    },
    ghostSecondary: {
      borderColor: 'transparent',
      backgroundColor: 'transparent',
      color: 'gray600',
      $hover: {
        backgroundColor: 'transparent',
        color: 'gray800',
      },
      $active: {
        backgroundColor: 'transparent',
        color: 'gray800',
      },
    },
    ghostSecondaryHoverBackground: {
      borderColor: 'transparent',
      backgroundColor: 'transparent',
      color: 'gray600',
      $hover: {
        backgroundColor: 'neutral400',
        color: 'gray800',
      },
      $active: {
        backgroundColor: 'transparent',
        color: 'gray800',
      },
    },
    ghostSecondaryGray: {
      borderColor: 'transparent',
      backgroundColor: 'transparent',
      color: 'gray50',
      $hover: {
        backgroundColor: 'transparent',
        color: 'gray800',
      },
      $active: {
        backgroundColor: 'transparent',
        color: 'gray800',
      },
    },
  },
  checkbox: {
    unchecked: {
      backgroundColor: 'white',
      borderColor: 'neutral700',
      $hover: {
        color: 'neutral100',
      },
    },
    checked: {
      borderColor: 'primary600',
      backgroundColor: 'primary600',
      color: 'white',
      $hover: {
        color: 'primary25',
      },
    },
  },
  tooltip: {
    backgroundColor: 'gray600',
    color: 'white',
  },
  textInput: {
    filter: {
      outline: 'invert',
      color: 'gray600',
      backgroundColor: 'white',
      borderColor: 'neutral700',
      $hover: {
        borderColor: 'neutral700',
      },
      $active: {
        borderColor: 'neutral700',
        boxShadow: 'none',
      },
    },
    form: {
      outline: 'none',
      color: 'gray600',
      backgroundColor: 'neutral100',
      borderColor: 'neutral500',
      $hover: {
        borderColor: 'primary600',
      },
      $active: {
        borderColor: 'primary600',
        boxShadow: '0px 0px 0px 3px #B2D5FF',
      },
    },
  },
  fonts: {
    root: rootFont,
  },
  customFonts: {
    [rootFont]: {
      default: rootFont,
    },
  },
  table: {
    borderColor: 'neutral700',
    backgroundColor: 'white',
    th: {
      backgroundColor: 'neutral100',
      color: 'gray50',
      $hover: {
        color: 'neutral700',
      },
    },
    td: {
      backgroundColor: 'white',
      color: 'gray50',
    },
    bulkActionBar: {
      backgroundColor: 'primary600',
      color: 'white',
    },
  },
  select: {
    form: {
      color: 'gray600',
      fontWeight: '400',
      borderColor: 'neutral500',
      $active: {
        borderColor: 'primary600',
      },
      $placeholder: {
        color: 'gray50',
      },
    },
    filter: {
      color: 'gray600',
      fontWeight: '500',
      borderColor: 'neutral500',
      $active: {
        borderColor: 'neutral500',
      },
      $placeholder: {
        color: 'gray600',
      },
    },
  },
});

export type BaseTheme = typeof baseTheme;
declare module 'dripsy' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DripsyCustomTheme extends BaseTheme {}
}

export default baseTheme;
