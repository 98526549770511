import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import CostCalculatorContainer from './components/costCalculator/CostCalculatorContainer';
import { useDispatch } from 'react-redux';
import CustomerCostReportApi from '../../api/customerCostReportApi';
import {
  setSelectedReport,
  setFormInputs,
} from '../../state/customerCostReports';
import useAxiosPrivate from '../../hooks/useAxiosPrivate';
import { reportToFormInputs } from '../../state/customerCostReports/utils/reportToFormInputs';
import { Text } from '../../aurora/typography/Text/Text';

const CostCalculatorPage = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const axios = useAxiosPrivate();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchReport = async () => {
      if (id) {
        setLoading(true);
        try {
          const currentReport =
            await CustomerCostReportApi.getArtycCustomerCostReport(axios, id);
          dispatch(setSelectedReport(currentReport));
          dispatch(setFormInputs(reportToFormInputs(currentReport)));
        } catch (error) {
          console.error('Error fetching report:', error);
        } finally {
          setLoading(false);
        }
      }
    };

    fetchReport();
    return () => {
      dispatch(setSelectedReport(null));
    };
  }, [id]);

  if (loading) {
    return (
      <Text size="m" sx={{ margin: '12px' }}>
        Loading...
      </Text>
    );
  }

  return <CostCalculatorContainer adminView={true} />;
};

export default CostCalculatorPage;
