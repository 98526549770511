import React, { useEffect, useState } from 'react';
import { EventEntity } from '../../../state/events/types';
import ShipmentSlideout from '../../../components/shipmentSlideout/ShipmentSlideout';
import { ShipmentEntity } from '../../../state/shipments/types';
import useAxiosPrivate from '../../../hooks/useAxiosPrivate';
import { useSelector } from 'react-redux';
import { selectAuth } from '../../../state/auth';
import ShipmentsApi from '../../../api/shipmentsApi';

interface Props {
  event: EventEntity | undefined;
  showGraph: () => void;
  open: boolean;
  closeSlideout: () => void;
}
const EventShipmentSlideout = ({
  event,
  showGraph,
  open,
  closeSlideout,
}: Props) => {
  const axiosPrivate = useAxiosPrivate();
  const auth = useSelector(selectAuth);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [shipment, setShipment] = useState<ShipmentEntity | undefined>(
    undefined
  );

  const loadData = async () => {
    try {
      setLoading(true);
      setError(false);

      const shipmentResp = await ShipmentsApi.getShipment(
        axiosPrivate,
        auth,
        event!.shipment
      );
      setShipment(shipmentResp);
    } catch (e) {
      setError(true);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (event) {
      loadData();
    }
  }, [event]);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error || (event && shipment === null)) {
    return <div>An error occurred, please close and open again</div>;
  }

  return (
    <ShipmentSlideout
      shipment={shipment}
      showGraph={showGraph}
      selectedEventId={event ? event._id : undefined}
      closeSlideout={closeSlideout}
      open={open}
    />
  );
};

export default EventShipmentSlideout;
