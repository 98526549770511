import React, { PropsWithChildren, ReactNode } from 'react';
import { useDripsyTheme, useSx } from 'dripsy';
import { useHover } from 'react-native-web-hooks';
import { Text } from '../../typography/Text/Text';
import {
  useFloating,
  shift,
  autoUpdate,
  autoPlacement,
  offset,
  Placement,
} from '@floating-ui/react';

interface Props {
  label: ReactNode;
  placement?: Placement;
}

export const Tooltip = ({
  children,
  label,
  placement,
}: PropsWithChildren<Props>) => {
  const { theme } = useDripsyTheme();
  const sx = useSx();
  const tooltipSx = sx(theme.tooltip);

  const { refs, floatingStyles } = useFloating({
    placement,
    middleware: [
      shift(),
      offset(8),
      ...(placement === undefined ? [autoPlacement()] : []),
    ],
    whileElementsMounted: autoUpdate,
  });

  const isHoveredChild = useHover(refs.reference);
  const isHoveredLabel = useHover(refs.floating);
  const display =
    isHoveredChild || isHoveredLabel
      ? { display: 'block' }
      : { display: 'none' };

  return (
    <div style={{ position: 'relative' }}>
      <div
        ref={refs.setFloating}
        style={{
          zIndex: 99,
          borderRadius: 4,
          padding: 8,
          ...display,
          ...tooltipSx,
          ...floatingStyles,
        }}
      >
        <Text size="xs" sx={{ color: tooltipSx.color, minWidth: '150px' }}>
          {label}
        </Text>
      </div>
      <div ref={refs.setReference} style={{ width: 'fit-content' }}>
        {children}
      </div>
    </div>
  );
};
