import React, { useState } from 'react';
import { Button } from '../../../../../aurora/components/Button/Button';
import { useDispatch, useSelector } from 'react-redux';
import { isArtycAdmin, selectAuth } from '../../../../../state/auth';
import { RootState } from '../../../../../state/store';

import CustomerCostReportApi from '../../../../../api/customerCostReportApi';
import useAxiosPrivate from '../../../../../hooks/useAxiosPrivate';
import { DeviceType } from '../../../../../state/devices/types';
import {
  CustomerCostReportFormBody,
  PayloadTypeEnum,
} from '../../../../../state/customerCostReports/types';
import {
  setSelectedReport,
  updateIndustryEstimates,
} from '../../../../../state/customerCostReports';
import { reportToFormInputs } from '../../../../../state/customerCostReports/utils/reportToFormInputs';
import { showToast } from '../../../../../aurora/components/Toast/Toast';
import { useNavigate } from 'react-router-dom';

interface Props {
  industryEstimateName?: string;
  label: string;
  variant: 'primary' | 'secondary';
  onSuccess: (isCreate: boolean, reportId: string) => void;
}

const SaveButton = ({
  industryEstimateName,
  label,
  variant,
  onSuccess,
}: Props) => {
  const auth = useSelector(selectAuth);
  const axios = useAxiosPrivate();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [saving, setSaving] = useState(false);

  const { formInputs, selectedReport } = useSelector((state: RootState) => ({
    formInputs: state.customerCostReports.formInputs,
    selectedReport: state.customerCostReports.selectedReport,
  }));

  const reportVersion =
    (useSelector(
      (state: RootState) => state.customerCostReports.selectedReport?.version
    ) as string | undefined) ?? '3';

  const areObjectsEqual = (
    obj1: object | undefined,
    obj2: object | undefined
  ) => JSON.stringify(obj1) === JSON.stringify(obj2);

  const isIndustryEstimate = industryEstimateName !== undefined;
  const isCompanyNameUnfilled =
    formInputs.contactInfoInputs.companyName === '' && !isIndustryEstimate;

  // convert the report (initial state) to a form to compare with the current state
  // formerResults don't get updated in the form so don't compare those
  const isFormUnchanged =
    selectedReport !== null &&
    areObjectsEqual(formInputs, reportToFormInputs(selectedReport));

  const handleSave = async () => {
    setSaving(true);

    const customerCostReportSubmission: CustomerCostReportFormBody = {
      version: reportVersion,
      deviceType: DeviceType.MedstowMicro,
      payload: PayloadTypeEnum.BloodSample,
      estimateInputs: formInputs.estimateInputs,
      dynamicInputs: formInputs.dynamicInputs,
      ...(isIndustryEstimate
        ? {
            companyName: '',
            artycComparisons: [],
            industryComparisons: [],
            industryEstimateName,
          }
        : {
            companyName: formInputs.contactInfoInputs.companyName,
            imageId: formInputs.contactInfoInputs.imageId,
            contactFirstName: formInputs.contactInfoInputs.contactFirstName,
            contactLastName: formInputs.contactInfoInputs.contactLastName,
            contactEmail: formInputs.contactInfoInputs.contactEmail,
            artycComparisons: formInputs.artycComparisons,
            industryComparisons: formInputs.industryComparisons,
            considerations: formInputs.considerations,
            industryEstimateName: undefined,
          }),
    };

    try {
      let report;

      const isCreate = selectedReport === null;
      if (isCreate) {
        report = await CustomerCostReportApi.createCustomerCostReport(
          axios,
          customerCostReportSubmission
        );
      } else {
        if (isFormUnchanged) {
          // don't need to bother making another request if nothing has changed
          report = selectedReport;
        } else {
          report = await CustomerCostReportApi.updateCustomerCostReport(
            axios,
            selectedReport._id,
            customerCostReportSubmission
          );
        }
      }
      dispatch(setSelectedReport(report));

      showToast({
        type: 'success',
        title: 'Success!',
        text: 'Form successfully saved!',
      });

      if (isIndustryEstimate) {
        dispatch(updateIndustryEstimates(report));
        navigate('/portal/customer-cost-reports', { replace: isCreate });
      } else {
        onSuccess(isCreate, report._id);
      }
    } catch (error) {
      showToast({
        type: 'error',
        title: 'Error',
        text: 'Error saving form, please try again or contact support',
      });
    } finally {
      setSaving(false);
    }
  };

  if (!isArtycAdmin(auth)) {
    return null;
  }

  return (
    <Button
      variant={variant}
      label={label}
      onClick={handleSave}
      disabled={isCompanyNameUnfilled || saving}
    />
  );
};

export default SaveButton;
