import React, { useEffect } from 'react';
import DeviceTypePopover from './DeviceTypePopover';
import { useDispatch, useSelector } from 'react-redux';
import Filter, {
  PopoverContentProps,
} from '../../../aurora/components/Filter/Filter';
import { DeviceType } from '../../../state/devices/types';
import styles from './DeviceTypeFilter.module.scss';
import { selectAuth } from '../../../state/auth';
import { RootState } from '../../../state/store';
import DevicesApi from '../../../api/devicesApi';
import useAxiosPrivate from '../../../hooks/useAxiosPrivate';
import { setDeviceTypeList } from '../../../state/devices';
interface DeviceTypeFilterProps {
  setDeviceTypesAction: (deviceTypes?: DeviceType[]) => any;
  filteredDeviceTypes?: DeviceType[];
}

const DeviceTypeFilter = ({
  setDeviceTypesAction,
  filteredDeviceTypes = [],
}: DeviceTypeFilterProps) => {
  const dispatch = useDispatch();
  const auth = useSelector(selectAuth);
  const axiosPrivate = useAxiosPrivate();
  const deviceTypes = useSelector(
    (state: RootState) => state.devices.deviceTypeList
  );

  useEffect(() => {
    const fetchDeviceTypes = async () => {
      if (deviceTypes === null) {
        try {
          const result = await DevicesApi.getDeviceTypes(axiosPrivate, auth);
          dispatch(setDeviceTypeList(result.deviceTypes));
        } catch (error) {
          console.log('faied to fetch device types:', error);
        }
      }
    };

    fetchDeviceTypes();
  }, [deviceTypes, axiosPrivate, auth, dispatch]);

  const onClear = () => {
    if (filteredDeviceTypes?.length !== 0) {
      dispatch(setDeviceTypesAction(undefined));
    }
  };

  const onFilter = (deviceTypes: DeviceType[]) =>
    dispatch(setDeviceTypesAction(deviceTypes));

  const toFiltersSummary = (deviceTypes: DeviceType[]) =>
    deviceTypes.length.toString();

  const popover = ({
    onSubmit,
    onCancel,
    onClear,
  }: PopoverContentProps<DeviceType[]>) => {
    return (
      <DeviceTypePopover
        onSubmit={onSubmit}
        onCancel={onCancel}
        onClear={onClear}
        deviceTypes={deviceTypes}
        filteredDeviceTypes={filteredDeviceTypes || []}
      />
    );
  };

  return (
    <Filter
      label="Device"
      popoverContent={popover}
      onSubmit={onFilter}
      onClear={onClear}
      toFiltersSummary={toFiltersSummary}
      className={styles.deviceTypeFilter}
    />
  );
};

export default DeviceTypeFilter;
