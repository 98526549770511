import React, { useEffect, useState } from 'react';
import TextInput from '../../../aurora/components/TextInput/TextInput';
import { IconSearch } from '../../../aurora/icons';
import { Text } from '../../../aurora/typography/Text/Text';
import { Button } from '../../../aurora/components/Button/Button';
import CompanyCheckboxList from './CompanyCheckboxList';
import { useSx } from 'dripsy';
import { CompanyEntity } from '../../../state/companies/types';

interface Props {
  onSubmit: (companyIds: string[]) => void;
  onClear: () => void;
  onCancel: () => void;
  companies: CompanyEntity[];
  filteredCompanyIds: string[];
}

/* 
  for now, we just pass through companies and assume we've fetched them prior
  the companies list isn't very long yet and isn't changing often
  when the companies list gets sufficiently long, we'll need to rethink it
  - we'll need to fetch companies within the component itself
  - company checkbox list should probably paginate and infinite scroll
  - search should refetch based on query
*/
const CompanyPopover = ({
  onSubmit,
  onClear,
  onCancel,
  companies,
  filteredCompanyIds,
}: Props) => {
  const sx = useSx();
  const [selectedCompanyIds, setCompanyIds] =
    useState<string[]>(filteredCompanyIds);
  const [searchQuery, setSearchQuery] = useState<string>('');

  useEffect(() => {
    setCompanyIds(filteredCompanyIds);
  }, [filteredCompanyIds]);

  const shownCompanies = companies.filter((company) =>
    company.companyName.toLowerCase().includes(searchQuery)
  );

  const onApply = () => {
    // assume that if they removed all the filters, they're not trying to filter by no companies
    // they're probably trying to clear the filter
    if (selectedCompanyIds.length === 0) {
      onClear();
    } else {
      onSubmit(selectedCompanyIds);
    }
  };

  return (
    <div style={{ minWidth: '300px' }}>
      <TextInput
        variant="filter"
        iconLeft={IconSearch}
        placeholder="Search by company name"
        onChange={(text) => setSearchQuery(text.toLowerCase())}
      />
      <div
        style={sx({
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          marginTop: '16px',
          paddingTop: '6px',
          borderTop: '1px solid',
          borderColor: 'neutral700',
        })}
      >
        <Text size="s" sx={{ fontWeight: '500' }}>
          Companies
        </Text>
        <Button variant="ghostPrimary" label="Clear all" onClick={onClear} />
      </div>
      <CompanyCheckboxList
        selectedCompanyIds={selectedCompanyIds}
        companies={shownCompanies}
        onChange={(companyIds) => setCompanyIds(companyIds)}
      />
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          marginTop: '16px',
          gap: '12px',
        }}
      >
        <Button variant="secondary" label="Cancel" onClick={onCancel} />
        <Button variant="primary" label="Apply" onClick={onApply} />
      </div>
    </div>
  );
};

export default CompanyPopover;
