import React from 'react';
import { DateRangePicker } from '../../../aurora/components/DateRangePicker/DateRangePicker';
import MobileDateRangePicker from '../MobileDateRangePicker/MobileDateRangePicker';
import styles from './PageDateFilter.module.scss';
import {
  DateRangeType,
  DateType,
} from '../../../aurora/components/DateRangePicker/DateRangeCalendar';
import { endOfDay } from 'date-fns';

interface Props {
  setDateFilters: (startDate: DateType, endDate: DateType) => void;
  clearDateFilters: () => void;
  filters: DateRangeType;
}

const PageDateFilter = ({
  setDateFilters,
  clearDateFilters,
  filters,
}: Props) => {
  const [startDate, endDate] = filters;
  return (
    <>
      <div className={styles.hideForMobile}>
        <DateRangePicker
          maxDate={new Date()}
          onSetRange={(startDate, endDate) => {
            setDateFilters(startDate, endDate ? endOfDay(endDate) : undefined);
          }}
          managedRange={[startDate, endDate]}
        />
      </div>
      <div className={styles.hideForDesktop}>
        <MobileDateRangePicker
          maxDate={new Date()}
          onClearRange={clearDateFilters}
          onApplyRange={(range) => setDateFilters(range[0], range[1])}
          currentDateRange={[startDate, endDate]}
        />
      </div>
    </>
  );
};

export default PageDateFilter;
