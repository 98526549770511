import React, { useEffect, useRef, useState } from 'react';

import { Button } from '../../../aurora/components/Button/Button';
import styles from './CompanyForm.module.scss';
import { CompanyEntity } from '../../../state/companies/types';
import TextInput from '../../../aurora/components/TextInput/TextInput';
import { showToast } from '../../../aurora/components/Toast/Toast';

interface Props {
  company?: CompanyEntity;
  onSubmit: (companyName: string) => Promise<any>;
  onClose: () => void;
  successMessage: string;
  errorMessage: string;
}
const CompanyForm = ({
  company,
  onSubmit,
  onClose,
  successMessage,
  errorMessage,
}: Props) => {
  const [companyName, setCompanyName] = useState(company?.companyName || '');
  const [isSubmitting, setIsSubmitting] = useState(false);

  const formRef = useRef<HTMLInputElement>(null);
  // set form focus
  useEffect(() => {
    formRef.current?.focus();
  }, []);

  const handleSubmit = async () => {
    setIsSubmitting(true);
    try {
      await onSubmit(companyName);

      onClose();
      showToast({
        type: 'success',
        title: 'Success!',
        text: successMessage,
      });
    } catch (err) {
      showToast({
        type: 'error',
        title: 'Error',
        text: errorMessage,
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <>
      <form className={styles.form}>
        <TextInput
          label="Company Name"
          onChange={setCompanyName}
          variant="form"
          managedText={companyName}
        />
        <div className={styles.buttonContainer}>
          <Button
            variant="secondary"
            disabled={isSubmitting}
            label="Cancel"
            onClick={onClose}
          />
          <Button
            variant="primary"
            disabled={isSubmitting || companyName === ''}
            label="Save"
            onClick={handleSubmit}
          />
        </div>
      </form>
    </>
  );
};

export default CompanyForm;
