import React from 'react';
import { Text } from '../../../../../aurora/typography/Text/Text';
import { CustomerCostReportEntity } from '../../../../../state/customerCostReports/types';
import styles from './Report.module.scss';

interface Props {
  report: CustomerCostReportEntity;
}

const Considerations = ({ report }: Props) => {
  if (report.considerations === undefined) {
    return null;
  }

  return (
    <div className={styles.considerations}>
      <Text size="l" className={styles.considerationHeader}>
        Other Considerations
      </Text>
      <Text size="m">{report.considerations}</Text>
    </div>
  );
};

export default Considerations;
