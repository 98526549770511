import { SxProp } from 'dripsy';
import baseTheme from '../../aurora/theme/baseTheme';

const { table, fontSizes } = baseTheme;

export const tableContainerStyle: SxProp = {
  borderRadius: 12,
  border: '1px solid',
  borderColor: table.borderColor,
  backgroundColor: table.backgroundColor,
};

export const tableHeaderSeparator: SxProp = {
  borderBottom: '1px solid',
  borderColor: baseTheme.table.borderColor,
};

export const tableHeaderRowCellStyle: SxProp = {
  fontSize: fontSizes.$0,
  color: table.th.color,
  backgroundColor: table.th.backgroundColor,
  fontWeight: '400',
  padding: '12px 24px',
  borderBottom: '1px solid',
  borderColor: table.borderColor,
};

export const headerCellWithArrowStyle: SxProp = {
  display: 'flex',
  alignItems: 'center',
  gap: '2px',
  justifyContent: 'center',
  paddingLeft: '14px',
};

export const tableRowStyle: SxProp = {
  fontSize: fontSizes.$2,
  color: table.td.color,
  backgroundColor: table.td.backgroundColor,
  fontWeight: '400',
  borderBottom: '1px solid',
  borderColor: table.borderColor,
  padding: '16px 24px',
  textAlign: 'center',
};

export const bulkActionBarStyle: SxProp = {
  backgroundColor: table.bulkActionBar.backgroundColor,
  color: table.bulkActionBar.color,
  height: '56px',
  display: 'flex',
  justifyContent: 'space-between',
};

export const bulkActionBarSectionStyle: SxProp = {
  display: 'flex',
  alignItems: 'center',
};

export const drawerStyle: SxProp = {
  borderBottom: '1px solid',
  borderColor: table.borderColor,
};
