import React, { useEffect, useRef, useState } from 'react';

import { ROLES } from '../state/auth/types';
const { ARTYC_ADMIN, COMPANY_ADMIN, COMPANY_EMPLOYEE } = ROLES;

import styles from './UserForm.module.scss';
import { isArtycAdmin, isCompanyEmployee, selectAuth } from '../state/auth';
import { useSelector } from 'react-redux';
import { CompanyEntity } from '../state/companies/types';
import { RootState } from '../state/store';
import { UserEntity } from '../state/users/types';
import { Button } from '../aurora/components/Button/Button';
import TextInput from '../aurora/components/TextInput/TextInput';
import Select from '../aurora/components/Select/Select';
import { showToast } from '../aurora/components/Toast/Toast';
import NoticeModal from './NoticeModal/NoticeModal';

const EMAIL_REGEX = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

interface Props {
  forUser?: UserEntity;
  // TODO: make it not "any"
  onSubmit: (data: any) => Promise<any>;
  onClose: () => void;
  onDelete?: () => Promise<void>;
  successMessage: string;
  submitText: string;
}

interface InviteUserData {
  firstName: string;
  lastName: string;
  email: string;
  companyId: string;
  role: string;
  password?: string;
  newPassword?: string;
}
const UserForm = ({
  forUser,
  onSubmit,
  onClose,
  onDelete,
  successMessage,
  submitText,
}: Props) => {
  const auth = useSelector(selectAuth);

  const [firstName, setFirstName] = useState(forUser?.firstName || '');
  const [lastName, setLastName] = useState(forUser?.lastName || '');
  const [email, setEmail] = useState(forUser?.email || '');
  const [companyId, setCompanyId] = useState(forUser?.companyId || '');
  const [userId, setUserId] = useState(forUser?._id || '');
  const [role, setRole] = useState(forUser?.role || '');

  const companies = useSelector((state: RootState) => state.companies.data);
  const [companyList, setCompanyList] = useState<CompanyEntity[]>([]);

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [password, setPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [isEditingSelf, setIsEditingSelf] = useState(false);

  const [showConfirmDelete, setShowConfirmDelete] = useState(false);

  const formRef = useRef<HTMLInputElement>(null);
  useEffect(() => {
    formRef.current?.focus();
  }, []);

  // set data if user data if form is opened for a specific user
  useEffect(() => {
    if (forUser) {
      setFirstName(forUser.firstName);
      setLastName(forUser.lastName);
      setEmail(forUser.email);
      setUserId(forUser._id);
      setRole(forUser.role);
    }
  }, [forUser]);

  useEffect(() => {
    if (userId === auth?._id) {
      setIsEditingSelf(true);
    } else {
      setIsEditingSelf(false);
    }
  }, [userId]);

  useEffect(() => {
    if (role === ARTYC_ADMIN.pid) {
      setCompanyId(
        companies.find((company) => company.companyName === 'Artyc')?._id || ''
      );
    }
  }, [role]);

  // get company list
  useEffect(() => {
    if (isArtycAdmin(auth)) {
      const filteredCompanies = companies.filter(
        (company) => company.companyName !== 'Artyc'
      );
      setCompanyList(filteredCompanies);
    }
    setCompanyId(auth?.companyId || '');
  }, []);

  const handleError = (statusCode: number) => {
    let errorMessage = '';
    switch (statusCode) {
      case 409:
        errorMessage = 'User already has an account';
        break;
      default:
        errorMessage =
          'There was a problem inviting the new user. Please ensure all fields are correctly filled and try again. If the problem persists, contact support.';
    }
    showToast({ type: 'error', title: 'Error', text: errorMessage });
  };

  const handleSubmit = async () => {
    setIsSubmitting(true);
    try {
      let data: InviteUserData = {
        firstName,
        lastName,
        email: email.trim().toLowerCase(),
        companyId,
        role,
      };

      if (isEditingSelf) {
        data.password = password.trim();
        data.newPassword = newPassword.trim();
      }
      await onSubmit(data);

      onClose();
      showToast({
        type: 'success',
        title: 'Success!',
        text: successMessage,
      });
    } catch (err: any) {
      if (err.response) {
        handleError(err.response.status);
      }
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleDelete = async () => {
    setShowConfirmDelete(false);
    setIsSubmitting(true);

    try {
      if (onDelete !== undefined) {
        await onDelete();

        onClose();
        showToast({
          type: 'success',
          title: 'Success!',
          text: 'Successfully deleted user',
        });
      }
    } catch (err: any) {
      showToast({
        type: 'error',
        title: 'Error',
        text: 'Error deleting user. Please try again or contact support',
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  const roleTypes = [
    ['Company Admin', COMPANY_ADMIN.pid],
    ['Company Employee', COMPANY_EMPLOYEE.pid],
  ];
  if (isArtycAdmin(auth)) {
    roleTypes.push(['Artyc Admin', ARTYC_ADMIN.pid]);
  }
  const roleOptions = roleTypes.map((type) => ({
    value: type[1],
    label: type[0],
  }));
  const companyOptions = companyList.map((company) => ({
    value: company._id,
    label: company.companyName,
  }));

  const validEmail = EMAIL_REGEX.test(email);
  const formFilled =
    validEmail &&
    role !== '' &&
    companyId !== '' &&
    firstName !== '' &&
    lastName !== '';

  return (
    <>
      <form className={styles.form}>
        <div className={styles.inputContainer}>
          <TextInput
            label="First Name"
            onChange={setFirstName}
            variant="form"
            managedText={firstName}
          />
        </div>
        <div className={styles.inputContainer}>
          <TextInput
            label="Last Name"
            onChange={setLastName}
            variant="form"
            managedText={lastName}
          />
        </div>

        <div className={styles.inputContainer}>
          <TextInput
            label="Email"
            onChange={setEmail}
            variant="form"
            managedText={email}
            textContentType="emailAddress"
          />
        </div>
        {isCompanyEmployee(auth) ? null : (
          <div className={styles.inputContainer}>
            <Select
              label="Role"
              placeholder="Select Role"
              options={roleOptions}
              onChange={(option) => setRole(option.value)}
              variant="form"
              managedValue={role}
            />
          </div>
        )}

        {isArtycAdmin(auth) ? (
          <div className={styles.inputContainer}>
            <Select
              label="Company"
              placeholder="Select Company"
              options={companyOptions}
              onChange={(option) => setCompanyId(option.value)}
              variant="form"
              disabled={role === ARTYC_ADMIN.pid}
              managedValue={companyId}
            />
          </div>
        ) : null}

        {isEditingSelf && (
          <>
            <div className={styles.inputContainer}>
              <TextInput
                label="Existing Password"
                onChange={setPassword}
                variant="form"
                managedText={password}
                textContentType="password"
              />
            </div>
            <div className={styles.inputContainer}>
              <TextInput
                label="New Password"
                onChange={setNewPassword}
                variant="form"
                managedText={newPassword}
                textContentType="password"
              />
            </div>
          </>
        )}

        <div className={styles.buttonContainer}>
          {onDelete !== undefined ? (
            <>
              <Button
                variant="secondaryError"
                label="Delete"
                disabled={isSubmitting}
                onClick={() => setShowConfirmDelete(true)}
              />
              <NoticeModal
                title="Are you sure?"
                notice="This action cannot be undone."
                show={showConfirmDelete}
                onClose={() => setShowConfirmDelete(false)}
                buttons={
                  <>
                    <Button
                      variant="secondary"
                      label="Cancel"
                      onClick={() => setShowConfirmDelete(false)}
                    />
                    <Button
                      variant="secondaryError"
                      label="Delete"
                      onClick={handleDelete}
                    />
                  </>
                }
              />
            </>
          ) : (
            <Button
              variant="secondary"
              disabled={isSubmitting}
              label="Cancel"
              onClick={onClose}
            />
          )}
          <Button
            variant="primary"
            disabled={isSubmitting || !formFilled}
            label={submitText}
            onClick={handleSubmit}
          />
        </div>
      </form>
    </>
  );
};

export default UserForm;
