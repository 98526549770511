import React, { useRef, useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import axios from '../../../api/axios';
import { TextInput as NativeTextInput } from 'react-native';
import './loginForm.scss';
import { showToast } from '../../../aurora/components/Toast/Toast';
import TextInput from '../../../aurora/components/TextInput/TextInput';
import { Button } from '../../../aurora/components/Button/Button';
import { IconArrowLeft } from '../../../aurora/icons';
import styles from '../../../components/layout/Splash.module.scss';

const FORGOT_PASSWORD_URL = '/auth/password-reset';

interface Props {
  email: string;
  setEmail: React.Dispatch<React.SetStateAction<string>>;
  setSuccessfulSubmit: React.Dispatch<React.SetStateAction<boolean>>;
}

const ForgotPassword = ({ email, setEmail, setSuccessfulSubmit }: Props) => {
  const emailRef = useRef<NativeTextInput>(null);

  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    emailRef.current?.focus();
  }, []);

  const handleSubmit = async () => {
    setIsSubmitting(true);
    let errMsg: string;

    try {
      const sanitizedEmail = email.trim().toLowerCase();
      const response = await axios.post(
        FORGOT_PASSWORD_URL,
        JSON.stringify({ email: sanitizedEmail }),
        {
          headers: { 'Content-Type': 'application/json' },
          withCredentials: true,
        }
      );

      if (response.status === 200) {
        setIsSubmitting(false);
        setSuccessfulSubmit(true);
      }
    } catch (err: any) {
      if (!err?.response) {
        errMsg = 'No Server Response';
      } else if (err.response?.status === 400) {
        errMsg = 'Missing Email';
      } else if (err.response?.status === 404) {
        errMsg = 'Email Not Found';
      } else {
        errMsg = 'Request Failed';
      }
      showToast({
        type: 'error',
        title: 'Password Reset Error',
        text: errMsg,
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <>
      <form className={styles.form}>
        <div className={styles.formInputs}>
          <TextInput
            variant={'filter'}
            placeholder={'Enter your email'}
            label={'Email'}
            ref={emailRef}
            managedText={email}
            textContentType="emailAddress"
            onChange={(newEmail) => setEmail(newEmail.trim().toLowerCase())}
          />
        </div>
        <Button
          variant="primary"
          className={styles.formButton}
          label={'Reset password'}
          disabled={isSubmitting}
          onClick={handleSubmit}
        />
        <Link to="/login" className={styles.iconLink}>
          <IconArrowLeft width={20} height={20} />
          Back to Login
        </Link>
      </form>
    </>
  );
};

export default ForgotPassword;
