import React from 'react';
import { Path } from 'react-native-svg';

import { IconBase, IconProps } from './IconBase';

export const IconCheckCircleFill: React.FunctionComponent<IconProps> = ({
  ...props
}) => (
  <IconBase {...props}>
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 1C5.92487 1 1 5.92487 1 12C1 18.0751 5.92487 23 12 23C18.0751 23 23 18.0751 23 12C23 5.92487 18.0751 1 12 1ZM17.7134 9.70072C18.1004 9.30669 18.0947 8.67355 17.7007 8.28656C17.3067 7.89957 16.6735 7.90527 16.2866 8.2993L10.125 14.5729L7.71345 12.1175C7.32646 11.7235 6.69332 11.7177 6.29929 12.1047C5.90527 12.4917 5.89956 13.1249 6.28655 13.5189L8.6981 15.9743C9.48196 16.7724 10.768 16.7724 11.5519 15.9743L17.7134 9.70072Z"
      fill="currentColor"
      stroke="currentColor"
    />
  </IconBase>
);
