import React, { useState } from 'react';
import { TimeRangeGroup } from './TimeRangeGroup';
import { DateRangeType } from '../../../aurora/components/DateRangePicker/DateRangeCalendar';
import CompanyFilter from '../../../components/filters/CompanyFilter/CompanyFilter';
import styles from './FiltersBar.module.scss';
import PageDateFilter from '../../../components/filters/PageDateFilter/PageDateFilter';
import MobileCompanyFilter from '../../../components/filters/MobileChecklistFilter/MobileCompanyFilter';

interface Props {
  onFilterDate: (startDate?: Date, endDate?: Date) => void;
  setCompanyIds: (companyIds: string[] | undefined) => void;
  companyIds: string[] | undefined;
}

export const FiltersBar = ({
  onFilterDate,
  setCompanyIds,
  companyIds,
}: Props) => {
  const [activeIdx, setActiveIdx] = useState<number | null>(0);
  const [pickerRange, setPickerRange] = useState<DateRangeType>([
    undefined,
    undefined,
  ]);

  const onTimeRangeFilter = (idx: number, startDate?: Date, endDate?: Date) => {
    setActiveIdx(idx);
    // clear date picker since setting time range
    setPickerRange([undefined, undefined]);
    onFilterDate(startDate, endDate);
  };

  return (
    <div className={styles.filtersBar}>
      <TimeRangeGroup onFilter={onTimeRangeFilter} activeIdx={activeIdx} />
      <div className={styles.rightFilters}>
        <CompanyFilter
          setCompanyIdsAction={setCompanyIds}
          filteredCompanyIds={companyIds}
        />
        <MobileCompanyFilter
          setCompanyIdsAction={setCompanyIds}
          filteredCompanyIds={companyIds}
        />
        <PageDateFilter
          setDateFilters={(startDate, endDate) => {
            setPickerRange([startDate, endDate]);
            if (startDate !== undefined && endDate !== undefined) {
              // unselect time range group since set a date here
              setActiveIdx(null);
            } else {
              // cleared date picker, go back to all time
              setActiveIdx(0);
            }
            onFilterDate(startDate, endDate);
          }}
          clearDateFilters={() => setPickerRange([undefined, undefined])}
          filters={pickerRange}
        />
      </div>
    </div>
  );
};
