import React, { useEffect, useState } from 'react';
import { ShipmentEntity } from '../../../../state/shipments/types';
import TestingMap from '../../../../components/maps/TestingMap';
import useAxiosPrivate from '../../../../hooks/useAxiosPrivate';
import { useDispatch, useSelector } from 'react-redux';
import { selectAuth } from '../../../../state/auth';
import ShipmentsApi from '../../../../api/shipmentsApi';
import styles from '../graph/SnapshotsGraphContainer.module.scss';
import {
  selectSnapshotsByShipment,
  setSnapshots,
  ShipmentToSnapshotsMap,
} from '../../../../state/shipments';

interface Props {
  shipments: ShipmentEntity[];
}
const SnapshotsMapContainer = ({ shipments }: Props) => {
  const axiosPrivate = useAxiosPrivate();
  const auth = useSelector(selectAuth);
  const dispatch = useDispatch();
  const snapshotsByShipment = useSelector(selectSnapshotsByShipment);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  const loadSnapshots = async () => {
    try {
      setLoading(true);
      setError(false);

      for (let shipment of shipments) {
        if (snapshotsByShipment[shipment._id] === undefined) {
          const snapshots = await ShipmentsApi.getShipmentSnapshots(
            axiosPrivate,
            auth,
            shipment._id
          );
          dispatch(setSnapshots({ [shipment._id]: snapshots }));
        }
      }
    } catch (e) {
      setError(true);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    loadSnapshots();
  }, [shipments]);

  if (loading) {
    return <div className={styles.container}>Loading...</div>;
  }

  if (error || shipments.length === 0) {
    return (
      <div className={styles.container}>
        Error -- please close and open again
      </div>
    );
  }

  const hasGeoData = shipments.some((shipment) => shipment.hasGeoData);
  if (!hasGeoData) {
    return <div className={styles.container}>No data found</div>;
  }

  const filteredSnapshotsMap = shipments.reduce((acc, shipment) => {
    if (snapshotsByShipment[shipment._id]) {
      acc[shipment._id] = snapshotsByShipment[shipment._id];
    }
    return acc;
  }, {} as ShipmentToSnapshotsMap);

  return <TestingMap segments={filteredSnapshotsMap} />;
};

export default SnapshotsMapContainer;
