import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useAxiosPrivate from '../../../hooks/useAxiosPrivate';
import { isArtycAdmin, selectAuth } from '../../../state/auth';
import { loadCompanies } from '../../../state/companies';
import { DeviceType } from '../../../state/devices/types';
import { RootState } from '../../../state/store';
import MobileChecklistFilter from './MobileChecklistFilter';
import DevicesApi from '../../../api/devicesApi';
import { setDeviceTypeList } from '../../../state/devices';

interface CompanyFilterProps {
  setCompanyIdsAction: (companyIds: string[] | undefined) => void;
  filteredCompanyIds: string[] | undefined;
  setDeviceTypesAction: (deviceTypes?: DeviceType[]) => any;
  filteredDeviceTypes?: DeviceType[];
}

const MobileCompanyDeviceFilter = ({
  setCompanyIdsAction,
  filteredCompanyIds,
  setDeviceTypesAction,
  filteredDeviceTypes,
}: CompanyFilterProps) => {
  const dispatch = useDispatch();
  const axiosPrivate = useAxiosPrivate();
  const auth = useSelector(selectAuth);

  const deviceTypes = useSelector(
    (state: RootState) => state.devices.deviceTypeList
  );

  useEffect(() => {
    const fetchDeviceTypes = async () => {
      if (deviceTypes === null) {
        try {
          const result = await DevicesApi.getDeviceTypes(axiosPrivate, auth);
          dispatch(setDeviceTypeList(result.deviceTypes));
        } catch (error) {
          console.log('faied to fetch device types:', error);
        }
      }
    };

    fetchDeviceTypes();
  }, [deviceTypes, axiosPrivate, auth, dispatch]);

  const companiesState = useSelector((state: RootState) => state.companies);
  const companies = companiesState.data;

  const isArtyc = isArtycAdmin(auth);

  const fetchCompanies = async () => {
    const action = await loadCompanies(axiosPrivate, auth!, companiesState);
    dispatch(action);
  };

  useEffect(() => {
    if (companies.length === 0 && isArtyc) {
      fetchCompanies();
    }
  }, []);

  return (
    <MobileChecklistFilter
      sections={[
        {
          title: 'Companies',
          selectedIds: filteredCompanyIds || [],
          hasSearch: true,
          searchPlaceholder: 'Search by company name',
          options: companies.map((company) => ({
            id: company._id,
            label: company.companyName,
          })),
          show: isArtyc,
          onApplyFilters: (companyIds) => setCompanyIdsAction(companyIds),
          onClear: () => setCompanyIdsAction(undefined),
        },
        {
          title: 'Devices',
          selectedIds: filteredDeviceTypes || [],
          options: deviceTypes
            ? deviceTypes.map((type) => ({
                id: type,
                label: type,
              }))
            : [],
          show: true,
          onApplyFilters: (eventIds) =>
            setDeviceTypesAction(eventIds as DeviceType[]),
          onClear: () => setDeviceTypesAction(undefined),
        },
      ]}
    />
  );
};

export default MobileCompanyDeviceFilter;
