import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import CustomerCostReportApi from '../../../api/customerCostReportApi';
import { Button } from '../../../aurora/components/Button/Button';
import { Heading } from '../../../aurora/typography/Heading/Heading';
import { Text } from '../../../aurora/typography/Text/Text';
import useAxiosPrivate from '../../../hooks/useAxiosPrivate';
import { setIndustryEstimates } from '../../../state/customerCostReports';
import { reportToFormInputs } from '../../../state/customerCostReports/utils/reportToFormInputs';
import { mkCustomerCostDollars } from '../../../state/customerCostReports/utils/summaryUtils';
import { RootState } from '../../../state/store';
import styles from './IndustryEstimateLinks.module.scss';

const IndustryEstimateLinks = () => {
  const dispatch = useDispatch();
  const axios = useAxiosPrivate();
  const navigate = useNavigate();

  const industryEstimates = useSelector(
    (state: RootState) => state.customerCostReports.industryEstimates
  );

  useEffect(() => {
    const fetchEstimates = async () => {
      try {
        const estimates = await CustomerCostReportApi.getIndustryEstimates(
          axios
        );
        dispatch(setIndustryEstimates(estimates));
      } catch (error) {
        console.error('Error fetching report:', error);
      }
    };

    if (industryEstimates.length === 0) {
      fetchEstimates();
    }
  }, []);

  if (industryEstimates.length === 0) {
    return null;
  }

  const estimateObjs = industryEstimates
    .map((estimate) => {
      const inputs = reportToFormInputs(estimate);
      const totalPerShipment = mkCustomerCostDollars(
        inputs.estimateInputs,
        1
      ).totalPerShipment;
      const totalDollars = `$${totalPerShipment.toFixed(2)}`;

      return {
        estimate,
        totalPerShipment,
        totalDollars,
      };
    })
    .sort((a, b) => a.totalPerShipment - b.totalPerShipment);

  return (
    <>
      <Heading size="s" className={styles.heading}>
        Industry Estimates
      </Heading>
      <div className={styles.cardContainer}>
        {estimateObjs.map((obj) => (
          <div key={obj.estimate._id} className={styles.card}>
            <Text size="m">{obj.estimate.industryEstimateName}</Text>
            <div className={styles.cardBottom}>
              <Text size="m">{obj.totalDollars}</Text>
              <Button
                variant="ghostPrimary"
                onClick={() => {
                  navigate(`/portal/cost-calculator/${obj.estimate._id}`);
                }}
                label="Edit"
                className={styles.link}
              />
            </div>
          </div>
        ))}
      </div>
    </>
  );
};

export default IndustryEstimateLinks;
