import React, { useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import useAxiosPrivate from '../../hooks/useAxiosPrivate';
import useFeatureFlags from '../../hooks/useFeatureFlags';
import FeatureFlagsApi from '../../api/featureFlagsApi';
import NavBar from './NavBar/NavBar';
import styles from './Portal.module.scss';

const Portal = () => {
  const axiosPrivate = useAxiosPrivate();
  const { setFeatureFlags } = useFeatureFlags();

  useEffect(() => {
    const updateFlags = async () => {
      const flags = await FeatureFlagsApi.getFeatureFlags(axiosPrivate);
      setFeatureFlags(flags);
    };

    updateFlags();
  }, []);

  return (
    <div className={styles.portal}>
      <NavBar />
      <div className={styles.mainContainer}>
        <Outlet />
      </div>
    </div>
  );
};

export default Portal;
