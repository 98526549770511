import React from 'react';
import DataTableContainer from '../../components/table/DataTableContainer';
import { PcbTestEntity, PcbTestStatus } from '../../state/pcbTests/types';
import {
  loadPcbTests,
  resetPage,
  setCurrentPage,
  setDateFilter,
  setDeviceTypesFilter,
  setSearchFilter,
  sortPcbTests,
} from '../../state/pcbTests';
import PageHeader from '../../components/layout/PageHeader';
import {
  ColumnsConfig,
  EmptyConfig,
  SlideoutConfig,
  TopConfig,
} from '../../components/table/tableConfig';
import { IconCheck } from '../../aurora/icons';
import { formatDateTime } from '../../utils/dateUtil';
import PcbTestSlideout from './components/PcbTestSlideout';
import PcbTestStatusLabel from './components/PcbTestStatusLabel';
import PcbTestStatusFilter from './components/PcbTestStatusFilter';
import DeviceTypeFilter from '../../components/filters/DeviceTypeFilter/DeviceTypeFilter';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../state/store';
import styles from '../page.module.scss';
import PageDateFilter from '../../components/filters/PageDateFilter/PageDateFilter';
import TableSearch from '../../aurora/components/TableSearch/TableSearch';

const PcbTestsPage = () => {
  const dispatch = useDispatch();

  const { deviceTypes } = useSelector(
    (state: RootState) => state.pcbTests.selectedFilters || {}
  );
  const { startDate, endDate } = useSelector(
    (state: RootState) => state.shipments.selectedFilters
  ) || { startDate: undefined, endDate: undefined };
  const parsedStartDate = startDate ? new Date(startDate) : undefined;
  const parsedEndDate = endDate ? new Date(endDate) : undefined;

  const topConfig: TopConfig = {
    text: 'Pcb Tests',
    action: <PcbTestStatusFilter />,
    additionalBars: [
      <>
        <TableSearch
          placeholder="Search by board version, PCB number"
          onChange={(text) => {
            dispatch(setSearchFilter(text));
          }}
        />
        <div className={styles.filterGroup}>
          <DeviceTypeFilter
            setDeviceTypesAction={setDeviceTypesFilter}
            filteredDeviceTypes={deviceTypes}
          />
          <PageDateFilter
            setDateFilters={(startDate, endDate) =>
              dispatch(
                setDateFilter([
                  startDate?.toISOString(),
                  endDate?.toISOString(),
                ])
              )
            }
            clearDateFilters={() =>
              dispatch(setDateFilter([undefined, undefined]))
            }
            filters={[parsedStartDate, parsedEndDate]}
          />
        </div>
      </>,
    ],
  };

  const emptyConfig: EmptyConfig = {
    icon: IconCheck,
    title: 'No pcb tests found',
    body: "Once pcb tests are done, they'll show up here",
  };
  const columnsConfig: ColumnsConfig<PcbTestEntity> = {
    columns: [
      {
        title: 'Device Model',
        property: 'deviceType',
      },
      {
        title: 'PCB Number',
        property: 'pcbNumber',
      },
      {
        title: 'Status',
        property: 'status',
        customComponent: (status) => (
          <PcbTestStatusLabel status={status as PcbTestStatus} />
        ),
      },
      {
        title: 'Board Version',
        property: 'boardVersion',
      },
      {
        title: 'Tested At',
        property: 'createdAt',
        customData: (entity) => formatDateTime(entity.createdAt),
      },
    ],
  };

  const slideoutConfig: SlideoutConfig<PcbTestEntity> = {
    slideout: (pcbTest, open, closeSlideout) => (
      <PcbTestSlideout
        pcbTest={pcbTest}
        open={open}
        closeSlideout={closeSlideout}
      />
    ),
  };

  return (
    <>
      <PageHeader headingText="Pcb Tests" />
      <DataTableContainer
        topConfig={topConfig}
        emptyConfig={emptyConfig}
        columnsConfig={columnsConfig}
        sortAction={sortPcbTests}
        loadDataAction={loadPcbTests}
        pageAction={setCurrentPage}
        stateName="pcbTests"
        resetAction={resetPage}
        slideoutConfig={slideoutConfig}
      />
    </>
  );
};

export default PcbTestsPage;
