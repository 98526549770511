import React from 'react';
import CostCalculatorSummary, {
  CustomerCostDollarsProps,
} from '../summary/CostCalculatorSummary';

interface Props {
  name: string;
  priceDollars: number;
  customerCostDollars: CustomerCostDollarsProps;
  numShipments: number;
}
const ArtycCaasSummary = ({
  name,
  priceDollars,
  customerCostDollars,
  numShipments,
}: Props) => {
  // CaaS comparison uses our shipment cost instead of packaging and doesn't need tracking, shipping, warehousing
  const toSubtract =
    (customerCostDollars.shipping || 0) +
    (customerCostDollars.warehousing || 0) +
    (customerCostDollars.tracking || 0) +
    (customerCostDollars.packaging || 0);
  const newTotalPerShipment =
    customerCostDollars.totalPerShipment - toSubtract + priceDollars;
  const newTotal = newTotalPerShipment * numShipments;

  const caasCost: CustomerCostDollarsProps = {
    ...customerCostDollars,
    shipping: 0,
    warehousing: 0,
    tracking: 0,
    packaging: priceDollars,
    total: newTotal,
    totalPerShipment: newTotalPerShipment,
  };

  return (
    <CostCalculatorSummary
      header={name}
      packagingTitle="Price per Shipment"
      customerCostDollars={caasCost}
    />
  );
};

export default ArtycCaasSummary;
