import React, { ComponentType, forwardRef, useEffect, useState } from 'react';
import {
  NativeSyntheticEvent,
  TextInput as NativeTextInput,
  TextInputChangeEventData,
} from 'react-native';
import { SxProp, useSx, useDripsyTheme } from 'dripsy';
import { IconProps } from '../../icons/IconBase';
import { Text } from '../../typography/Text/Text';
import { BaseTheme } from '../../theme/baseTheme';
import { Tooltip } from '../Tooltip/Tooltip';
import { IconCircleInfo } from '../../icons';
import styles from './TextInput.module.scss';

export interface TextInputProps {
  variant: keyof BaseTheme['textInput'];
  onChange?: (text: string) => void;
  managedText?: string;
  defaultText?: string;
  placeholder?: string;
  iconLeft?: ComponentType<IconProps>;
  iconRight?: ComponentType<IconProps>;
  sx?: SxProp;
  disabled?: boolean;
  label?: string;
  tooltip?: string;
  labelSx?: SxProp;
  labelClassName?: string;
  className?: string;
  error?: boolean;
  textContentType?: 'password' | 'emailAddress';
}

const TextInput = forwardRef<NativeTextInput, TextInputProps>(
  function TextInput(
    {
      variant,
      onChange,
      managedText,
      defaultText,
      placeholder,
      iconLeft: IconLeft,
      iconRight: IconRight,
      sx: sxProp,
      disabled,
      label,
      tooltip,
      labelSx,
      labelClassName,
      className,
      error,
      textContentType,
    },
    ref
  ) {
    const { theme } = useDripsyTheme();
    const textInput = theme.textInput as BaseTheme['textInput'];
    const variantSx = textInput[variant];

    const [text, setText] = useState(
      managedText !== undefined
        ? managedText
        : defaultText !== undefined
        ? defaultText
        : ''
    );
    const [isFocused, setIsFocused] = useState(false);
    const [isHovered, setIsHovered] = useState(false);

    const handleFocus = () => setIsFocused(true);
    const handleBlur = () => setIsFocused(false);
    const handleMouseEnter = () => setIsHovered(true);
    const handleMouseLeave = () => setIsHovered(false);

    const sx = useSx();

    const handleChange = (
      event: NativeSyntheticEvent<TextInputChangeEventData>
    ) => {
      const newText = event.nativeEvent.text;
      setText(newText);
      onChange && onChange(newText);
    };

    useEffect(() => {
      if (managedText !== undefined) {
        setText(managedText);
      }
    }, [managedText]);

    const iconLeft = IconLeft ? (
      <div className={styles.iconLeft}>
        <IconLeft width={20} height={20} color={'gray600'} />
      </div>
    ) : null;

    const iconRight = IconRight ? (
      <div className={styles.iconRight}>
        <IconRight width={20} height={20} />
      </div>
    ) : null;

    const textInputStyles: SxProp = {
      height: '40px',
      width: '100%',
      lineHeight: '24px',
      color: variantSx.color,
      borderRadius: '8px',
      paddingX: '14px',
      paddingY: '10px',
      fontSize: '14px',
      border: '1px solid',
      outline: variantSx.outline,
      backgroundColor: variantSx.backgroundColor,
      boxShadow: isFocused && !disabled ? variantSx.$active.boxShadow : 'none',
      borderColor:
        isFocused && !disabled
          ? variantSx.$active.borderColor
          : isHovered && !disabled
          ? variantSx.$hover.borderColor
          : variantSx.borderColor,
      paddingLeft: iconLeft ? '42px' : undefined,
      paddingRight: iconRight ? '42px' : undefined,
      ...sxProp,
    };

    const disabledStyles: SxProp = {
      cursor: 'not-allowed',
      backgroundColor: 'neutral100',
      outline: 'none',
      color: 'neutral700',
    };

    const errorStyles: SxProp = {
      borderColor: 'error600',
      outlineColor: 'error600',
    };

    const id = label
      ? label.toLowerCase().replace(/[^a-z0-g]/g, '-')
      : undefined;
    const placeholderColor = sx({ color: 'gray50' }).color;

    const textInputContent = (
      <>
        {label && (
          <label htmlFor={id} className={styles.label}>
            <Text
              size="s"
              sx={{
                color: disabled ? disabledStyles.color : 'gray600',
                fontWeight: '500',
                letterSpacing: '-0.1px',
                lineHeight: '20px',
                ...labelSx,
              }}
              className={labelClassName}
            >
              {label}
            </Text>
            {tooltip && (
              <Tooltip label={tooltip}>
                <IconCircleInfo color="gray50" height={15} width={15} />
              </Tooltip>
            )}
          </label>
        )}
        <div className={styles.textInputWrapper}>
          {iconLeft}
          {iconRight}
          <NativeTextInput
            id={id}
            ref={ref}
            placeholder={placeholder}
            value={text}
            editable={!disabled}
            onChange={handleChange}
            placeholderTextColor={
              disabled ? disabledStyles.color : placeholderColor
            }
            onFocus={handleFocus}
            onBlur={handleBlur}
            // @ts-ignore
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            style={{
              ...sx(textInputStyles),
              ...(disabled ? sx(disabledStyles) : {}),
              ...(error ? sx(errorStyles) : {}),
            }}
            className={className}
            textContentType={textContentType}
            secureTextEntry={textContentType === 'password'}
            keyboardType={
              textContentType === 'emailAddress' ? 'email-address' : 'default'
            }
            autoCapitalize={
              textContentType === 'emailAddress' ? 'none' : 'sentences'
            }
          />
        </div>
      </>
    );

    return <div className={className}>{textInputContent}</div>;
  }
);

export default TextInput;
