import React, { CSSProperties } from 'react';
import { ShipmentEntity } from '../../state/shipments/types';
import TextInput from '../../aurora/components/TextInput/TextInput';
import { secondsToFullMinutes } from '../../utils/dateUtil';

interface Props {
  shipment: ShipmentEntity | undefined;
}

// for some reason our grid doesn't like gaps, so we manually construct the padding here
const mkInput = (
  label: string,
  text: string,
  lastInRow: boolean = false,
  lastInColumn: boolean = false
) => (
  <div
    style={{
      paddingRight: lastInRow ? 0 : '16px',
      paddingBottom: lastInColumn ? 0 : '16px',
    }}
  >
    <TextInput
      variant="filter"
      disabled={true}
      label={label}
      defaultText={text}
    />
  </div>
);

const degC = (temp: number): string => `${temp}°C`;
const inMinutes = (seconds: number): string => `${seconds} minutes`;

const TempManagementSection = ({ shipment }: Props) => {
  if (!shipment) {
    return null;
  }
  const twoColumnStyle: CSSProperties = {
    display: 'grid',
    gridTemplateColumns: '50% 50%',
  };

  if (shipment.shippingProfile === undefined) {
    return (
      <>
        <div style={twoColumnStyle}>
          {mkInput('Set Temperature', degC(shipment.setTemperature))}
          {mkInput(
            'Sampling Rate',
            inMinutes(shipment.logIncrement / 60 / 1000),
            true
          )}
          {mkInput('Excursion Window', inMinutes(30))}
          {mkInput('Cooldown Timeout', inMinutes(1.5 * 60), true, true)}
        </div>
      </>
    );
  }

  const {
    name,
    minSteadyStateTempC,
    maxSteadyStateTempC,
    samplingRateSec,
    excursionWindowSec,
    cooldownTimeoutSec,
    minCriticalTempC,
    maxCriticalTempC,
  } = shipment.shippingProfile;
  return (
    <>
      {mkInput('Profile Name', name)}
      <div style={twoColumnStyle}>
        {mkInput('Min. Temp', degC(minSteadyStateTempC))}
        {mkInput('Max. Temp', degC(maxSteadyStateTempC), true)}
        {mkInput(
          'Sampling Rate',
          inMinutes(secondsToFullMinutes(samplingRateSec))
        )}
        {mkInput(
          'Excursion Window',
          inMinutes(secondsToFullMinutes(excursionWindowSec)),
          true
        )}
        {minCriticalTempC !== undefined
          ? mkInput('Critical Temp. Low', degC(minCriticalTempC))
          : null}
        {maxCriticalTempC !== undefined
          ? mkInput('Critical Temp. High', degC(maxCriticalTempC), true)
          : null}
        {mkInput(
          'Cooldown Timeout',
          inMinutes(secondsToFullMinutes(cooldownTimeoutSec)),
          false,
          true
        )}
      </div>
    </>
  );
};

export default TempManagementSection;
