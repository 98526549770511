import React from 'react';
import {
  ShipmentEntity,
  ShipmentSnapshotEntity,
} from '../../../../state/shipments/types';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  ChartData,
  ChartDataset,
} from 'chart.js';
import AnnotationPlugin from 'chartjs-plugin-annotation';
import { Line } from 'react-chartjs-2';
import { secondsToDuration } from '../../../../utils/dateUtil';
import { htmlLegendPlugin } from './Legend';
import { ShipmentToSnapshotsMap } from '../../../../state/shipments';
import { convertTemp, mkChartOptions } from './chartOptions';

interface Props {
  shipments: ShipmentEntity[];
  snapshotsMap: ShipmentToSnapshotsMap;
  tempUnit: 'C' | 'F';
  isArtycAdmin: boolean;
  tempThresholdsC?: number[];
  minTempAxisC?: number;
  maxTempAxisC?: number;
}
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  AnnotationPlugin
);

const ShipmentsGraph = ({
  shipments,
  snapshotsMap,
  tempUnit,
  isArtycAdmin,
  tempThresholdsC,
  minTempAxisC,
  maxTempAxisC,
}: Props) => {
  const options = mkChartOptions(
    'duration',
    tempUnit,
    tempThresholdsC,
    [],
    minTempAxisC,
    maxTempAxisC
  );

  const totalSnapshots = Object.values(snapshotsMap)
    .map((snapshots) => snapshots.length)
    .reduce((sum, curr) => sum + curr, 0);
  const amountReduction = Math.ceil(totalSnapshots / 2500);

  const timestampSet = new Set<number>();
  Object.values(snapshotsMap).forEach((snapshots) => {
    snapshots.forEach((snapshot, i) => {
      if (amountReduction > 1 && i % amountReduction !== 0) {
        return;
      }
      timestampSet.add(snapshot.secSinceStart);
    });
  });
  // x axis is sorted + merged version of all the shipments' snapshots' timestamps
  const sortedTimestamps = Array.from(timestampSet).sort((a, b) => a - b);
  const xAxis = sortedTimestamps.map((seconds) =>
    secondsToDuration(seconds, true)
  );

  const datasets: ChartDataset<'line'>[] = [];
  shipments.forEach((shipment) => {
    const shipmentSnapshots = snapshotsMap[shipment._id];
    if (shipmentSnapshots === undefined || shipmentSnapshots.length === 0) {
      return;
    }

    const snapshots: ShipmentSnapshotEntity[] = [];
    let timestampIdx = 0;
    let snapshotIdx = 0;

    while (
      snapshotIdx < shipmentSnapshots.length &&
      timestampIdx < sortedTimestamps.length
    ) {
      const currTimestamp = sortedTimestamps[timestampIdx];
      const currSnapshot = shipmentSnapshots[snapshotIdx];

      // x axis skipped this snapshot, move snapshots ahead
      if (currSnapshot.secSinceStart < currTimestamp) {
        snapshotIdx = snapshotIdx + 1;
        // x axis has another timestamp before this one, fill in with same data and move timestamps ahead
      } else if (currSnapshot.secSinceStart > currTimestamp) {
        snapshots.push(currSnapshot);
        timestampIdx = timestampIdx + 1;
        // x axis is at this snapshot, proceed both
      } else {
        snapshots.push(currSnapshot);
        timestampIdx = timestampIdx + 1;
        snapshotIdx = snapshotIdx + 1;
      }
    }

    // assumes we'd only compare shipments across different units
    // we'll want a different identifier if we compare across the same unit
    const serialNumber = shipment.serialNumber;
    datasets.push(
      ...[
        {
          label: `${serialNumber} ${
            isArtycAdmin ? 'Evap' : 'External'
          } (°${tempUnit})`,
          data: snapshots.map((snapshot) =>
            convertTemp(tempUnit)(snapshot.temps.evap)
          ),
          // TODO: how do make the colors a bit different for each shipment?
          borderColor: '#7c31c6',
          yAxisID: 'temperature',
        },
        {
          label: `${serialNumber} ${
            isArtycAdmin ? 'Sleeve' : 'Internal'
          } (°${tempUnit})`,
          data: snapshots.map((snapshot) =>
            convertTemp(tempUnit)(snapshot.temps.sleeve)
          ),
          borderColor: '#0c77ff',
          yAxisID: 'temperature',
        },
        ...(isArtycAdmin
          ? [
              {
                label: `${serialNumber} Heat Sink (°${tempUnit})`,
                data: snapshots.map((snapshot) =>
                  convertTemp(tempUnit)(snapshot.temps.hs)
                ),
                borderColor: '#eea23e',
                yAxisID: 'temperature',
              },
              {
                label: `${serialNumber} Battery (%)`,
                data: snapshots.map((snapshot) => snapshot.soc),
                borderColor: '#5a6473',
                yAxisID: 'temperature',
              },
              {
                label: `${serialNumber} Output (V)`,
                data: snapshots.map((snapshot) => parseFloat(snapshot.outputV)),
                borderColor: '#29a63b',
                yAxisID: 'voltage',
              },
            ]
          : []),
      ]
    );
  });

  const data: ChartData<'line'> = {
    labels: xAxis,
    datasets,
  };

  return (
    <>
      <div
        id="legend-container"
        style={{
          display: 'flex',
          justifyContent: 'center',
          marginBottom: '12px',
        }}
      ></div>
      <Line options={options} data={data} plugins={[htmlLegendPlugin]} />
    </>
  );
};

export default ShipmentsGraph;
