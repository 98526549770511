import React from 'react';
import { IconMap } from '../../../aurora/icons';
import ActionButton from '../../../components/table/ActionButton';

interface Props {
  onClick: () => void;
}
const ShipmentMapButton = ({ onClick }: Props) => {
  return <ActionButton icon={IconMap} onClick={onClick} label="View map" />;
};

export default ShipmentMapButton;
