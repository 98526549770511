import React, { ReactNode } from 'react';
import NavBreadcrumbs from './NavBreadCrumbs';
import { Heading } from '../../aurora/typography/Heading/Heading';
import styles from './PageHeader.module.scss';

type Props = {
  headingText: string;
  action?: ReactNode;
};

const PageHeader: React.FC<Props> = ({ headingText, action }) => {
  return (
    <div className={styles.pageHeader}>
      <div className={styles.breadcrumbsContainer}>
        <NavBreadcrumbs />
      </div>
      <div className={styles.heading}>
        <Heading className={styles.headingText}>{headingText}</Heading>
        {action}
      </div>
    </div>
  );
};

export default PageHeader;
