import React from 'react';
import { IconGraph } from '../../../aurora/icons';
import ActionButton from '../../../components/table/ActionButton';

interface Props {
  onClick: () => void;
}
const ShipmentGraphButton = ({ onClick }: Props) => {
  return <ActionButton icon={IconGraph} onClick={onClick} label="View graph" />;
};

export default ShipmentGraphButton;
