import { format } from 'date-fns';

export const secondsToDuration = (
  seconds: number | null,
  includeSeconds?: boolean
): string => {
  if (seconds === null) {
    return '-';
  }

  const hours = Math.floor(seconds / (60 * 60));
  const minutes = Math.floor(seconds / 60.0 - hours * 60);

  if (minutes === 0 && hours === 0) {
    return `${seconds} sec`;
  }

  const remainingSeconds = Math.floor(
    seconds - (minutes * 60 + hours * 60 * 60)
  );
  const str =
    hours === 0
      ? `${minutes} min`
      : minutes === 0
      ? `${hours} hr`
      : `${hours} hr ${minutes} min`;
  return includeSeconds && remainingSeconds !== 0
    ? `${str} ${remainingSeconds} sec`
    : str;
};

export const secondsToFullMinutes = (seconds: number) => {
  return Math.floor(seconds / 60);
};

export const formatDateTime = (dateStr: string | null): string => {
  if (dateStr === null) {
    return '-';
  }

  return format(new Date(dateStr), 'MMM d, yyyy, h:mm a');
};

export const formatDate = (dateStr: string | null): string => {
  if (dateStr === null) {
    return '-';
  }

  return format(new Date(dateStr), 'MMM d, yyyy');
};

export const formatDateMonthDay = (date?: Date): string =>
  date !== undefined ? format(date, 'MMM d') : '';

export const formatGraphTime = (date: number | string): string => {
  return format(new Date(date), 'M/d h:mm a');
};

export const formatDateAsDuration = (
  date: number | string,
  startDate?: Date
) => {
  if (startDate === undefined) {
    throw new Error('expected start date');
  }

  const endDate = new Date(date);
  const seconds = Math.round((endDate.valueOf() - startDate.valueOf()) / 1000);
  return secondsToDuration(seconds, false);
};
