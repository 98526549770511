import { useSx } from 'dripsy';
import React, { ReactNode, useEffect, useState } from 'react';
import { Text } from '../../../../../../aurora/typography/Text/Text';
import Switch from '../../../../../../aurora/components/Switch/Switch';

interface Props {
  title: string;
  toggleSwitch?: {
    defaultValue: boolean;
    onSwitch?: (isOn: boolean) => void;
  };
  inputs: (disabled: boolean) => ReactNode;
}

const FormInputContainer = ({ title, toggleSwitch, inputs }: Props) => {
  const [isSectionOn, setIsSectionOn] = useState<boolean>(
    toggleSwitch !== undefined ? toggleSwitch.defaultValue : true
  );

  useEffect(() => {
    if (toggleSwitch !== undefined) {
      setIsSectionOn(toggleSwitch.defaultValue);
    }
  }, [toggleSwitch?.defaultValue]);

  const sx = useSx();
  const toggleComponent =
    toggleSwitch !== undefined ? (
      <Switch
        isSwitchOn={isSectionOn}
        onChange={() => {
          const newState = !isSectionOn;
          setIsSectionOn(newState);
          toggleSwitch.onSwitch && toggleSwitch.onSwitch(newState);
        }}
      />
    ) : null;

  return (
    <div
      style={sx({
        borderColor: 'neutral500',
        borderWidth: '1px',
        borderStyle: 'solid',
        borderRadius: '4px',
        padding: '8px 12px 12px 12px',
        minWidth: '250px',
      })}
    >
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          marginBottom: '8px',
        }}
      >
        <Text size="xs" sx={{ color: 'gray600', fontWeight: '500' }}>
          {title}
        </Text>
        {toggleComponent}
      </div>
      {inputs(!isSectionOn)}
    </div>
  );
};

export default FormInputContainer;
