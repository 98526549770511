import React from 'react';
import CostCalculatorSummary, {
  CustomerCostDollarsProps,
} from '../summary/CostCalculatorSummary';

interface Props {
  name: string;
  priceDollars: number;
  customerCostDollars: CustomerCostDollarsProps;
  numShippers: number;
  numShipments: number;
}
const ArtycLeaseSummary = ({
  name,
  priceDollars,
  customerCostDollars,
  numShipments,
  numShippers,
}: Props) => {
  // lease comparison uses our shipper cost instead of packaging and doesn't need tracking
  const toSubtract =
    ((customerCostDollars.tracking || 0) +
      (customerCostDollars.packaging || 0)) *
    numShipments;
  const toAdd = priceDollars * numShippers;
  const newTotal = customerCostDollars.total - toSubtract + toAdd;
  const newTotalPerShipment = newTotal / numShipments;

  const leaseCost: CustomerCostDollarsProps = {
    ...customerCostDollars,
    tracking: 0,
    packaging: priceDollars,
    total: newTotal,
    totalPerShipment: newTotalPerShipment,
  };

  return (
    <CostCalculatorSummary
      header={name}
      packagingTitle="Price per Shipper"
      customerCostDollars={leaseCost}
    />
  );
};

export default ArtycLeaseSummary;
