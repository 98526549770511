import React from 'react';
import { PcbTestStatus } from '../../../state/pcbTests/types';
import styles from './PcbTestStatusLabel.module.scss';

interface Props {
  status: PcbTestStatus;
}
const PcbTestStatusLabel = ({ status }: Props) => {
  const label = status.charAt(0).toUpperCase() + status.slice(1).toLowerCase();
  const className = status === 'success' ? styles.success : styles.failure;

  return <div className={`${className} ${styles.commonLabel}`}>{label}</div>;
};

export default PcbTestStatusLabel;
