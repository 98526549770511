import { AxiosInstance, AxiosResponse } from 'axios';
import { get, patch, post } from '../hooks/useAxiosPrivate';
import { CompanyEntity } from '../state/companies/types';
import { DataPayload } from '../state/types';

const COMPANY_URL = '/artyc/companies';

const CompaniesApi = {
  getCompanies: async (
    axios: AxiosInstance
  ): Promise<DataPayload<CompanyEntity>> => {
    const response: AxiosResponse<DataPayload<CompanyEntity>> = await get(
      axios,
      COMPANY_URL,
      {
        paginate: 'false',
      }
    );

    return response.data;
  },
  createCompany: async (axios: AxiosInstance, companyName: string) => {
    const response: AxiosResponse<CompanyEntity> = await post(
      axios,
      COMPANY_URL,
      {
        companyName,
      }
    );
    return response.data;
  },
  updateCompany: async (
    axios: AxiosInstance,
    companyId: string,
    companyName: string
  ) => {
    const response: AxiosResponse<{ success: string }> = await patch(
      axios,
      `${COMPANY_URL}/${companyId}`,
      {
        companyName,
      }
    );
    return response.data;
  },
};

export default CompaniesApi;
