import { EntityState } from '../types';

export interface DeviceEntity {
  _id: string;
  deviceType: string;
  serialNumber: string;
  companyName: string;
  companyId: string;
  pcbNumber: string;
  boardVersion: string;
  macAddress: string;
}

export enum DeviceType {
  MedstowMicro = 'Medstow Micro',
  Medstow5 = 'Medstow 5',
}

export interface DeviceFilters {
  search?: string;
  companyIds?: string[];
  deviceTypes?: DeviceType[];
}

export interface DeviceState extends EntityState<DeviceEntity> {
  selectedFilters?: DeviceFilters;
  deviceTypeList: DeviceType[] | null;
}
