import React from 'react';
import ModalAction from '../../../components/table/ModalAction';
import { loadCompanies } from '../../../state/companies';
import { CompanyEntity } from '../../../state/companies/types';
import { IconEdit } from '../../../aurora/icons';
import CompanyForm from './CompanyForm';
import CompaniesApi from '../../../api/companiesApi';
import useAxiosPrivate from '../../../hooks/useAxiosPrivate';

interface Props {
  company: CompanyEntity;
}
const CompanyEditButton = ({ company }: Props) => {
  const axiosPrivate = useAxiosPrivate();

  return (
    <ModalAction
      modalTitle="Edit company"
      loadDataAction={loadCompanies}
      stateName="companies"
      icon={IconEdit}
      modalContent={(onDataChange, onClose) => (
        <CompanyForm
          company={company}
          onSubmit={async (companyName) => {
            await CompaniesApi.updateCompany(
              axiosPrivate,
              company._id,
              companyName
            );
            await onDataChange();
          }}
          successMessage="Successfully updated company"
          errorMessage="Error updating company. Please try again or contact support"
          onClose={onClose}
        />
      )}
    />
  );
};

export default CompanyEditButton;
