import React, { PropsWithChildren } from 'react';
import { SxProp, useSx } from 'dripsy';
import baseTheme, { BaseTheme } from '../../theme/baseTheme';

interface Props {
  size?: keyof BaseTheme['text']['body'];
  sx?: SxProp;
  className?: string;
}

export const Text = ({
  children,
  size,
  sx,
  className,
}: PropsWithChildren<Props>) => {
  const sxProp = useSx();

  let combinedStyles = sx ? { ...sx } : {};

  if (size) {
    const textSx = baseTheme['text']['body'][size];
    combinedStyles = { ...combinedStyles, ...textSx };
  }

  const sxStyles = sxProp(combinedStyles);

  return (
    <div className={className} style={sxStyles}>
      {children}
    </div>
  );
};
