import React from 'react';
import { Text } from '../../../aurora/typography/Text/Text';
import { Button } from '../../../aurora/components/Button/Button';
import {
  ProfileType,
  ShippingProfileEntity,
} from '../../../state/shippingProfile/types';
import { secondsToFullMinutes } from '../../../utils/dateUtil';
import { IconClock, IconShield, IconSnow } from '../../../aurora/icons';
import styles from './cardStyle.module.scss';

interface Props {
  profile: ShippingProfileEntity;
  onEdit: () => void;
}
const ProfileCard = ({ profile, onEdit }: Props) => {
  const isCustom = profile.type === ProfileType.Custom;

  return (
    <div className={styles.cardContainer}>
      <div className={styles.cardHeader}>
        <Text size="l" className={styles.cardText}>
          {profile.name}
        </Text>
        <div className={isCustom ? styles.customType : styles.standardType}>
          {`• ${profile.type}`}
        </div>
      </div>
      <div className={styles.cardBody}>
        <Text
          size="s"
          className={styles.cardBodyRow}
          sx={{ marginBottom: '8px' }}
        >
          <IconSnow width={16} height={16} />
          {`Temperature Range: ${profile.minSteadyStateTempC}°C - ${profile.maxSteadyStateTempC}°C`}
        </Text>
        <Text
          size="s"
          className={styles.cardBodyRow}
          sx={{ marginBottom: '8px' }}
        >
          <IconClock width={16} height={16} />
          {`Sampling Rate: ${secondsToFullMinutes(
            profile.samplingRateSec
          )} Minutes`}
        </Text>
        <Text size="s" className={styles.cardBodyRow}>
          <IconShield width={16} height={16} />
          {`Defined Excursion: ${secondsToFullMinutes(
            profile.excursionWindowSec
          )} Minutes`}
        </Text>
      </div>
      <div className={styles.cardFooter}>
        <Button label="Edit" variant="ghostPrimary" onClick={onEdit} />
      </div>
    </div>
  );
};

export default ProfileCard;
