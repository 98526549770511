import React from 'react';
import ReactSelect, {
  components,
  DropdownIndicatorProps,
  StylesConfig,
} from 'react-select';
import { SxProp, useDripsyTheme, useSx } from 'dripsy';
import { IconChevronUp, IconChevronDown, IconCircleInfo } from '../../icons';
import { BaseTheme } from '../../theme/baseTheme';
import { Tooltip } from '../Tooltip/Tooltip';

const CustomDropdownIndicator = (props: DropdownIndicatorProps) => {
  const sx = useSx();
  const iconSize = { width: 20, height: 20 };

  const isDisabled = props.selectProps.isDisabled;

  const renderIcon = () => {
    const IconComponent = props.selectProps.menuIsOpen
      ? IconChevronUp
      : IconChevronDown;
    return (
      <IconComponent
        {...iconSize}
        style={sx({ color: isDisabled ? 'neutral700' : 'gray50' })}
      />
    );
  };

  return (
    <components.DropdownIndicator {...props}>
      {renderIcon()}
    </components.DropdownIndicator>
  );
};

const useSelectStyles = (
  variant: keyof BaseTheme['select']
): StylesConfig<any, false> => {
  const sx = useSx();
  const { theme } = useDripsyTheme();
  const variantSx = theme.select[variant];

  const commonFontStyles = {
    fontSize: '14px',
    ...sx({ color: 'gray700' }),
  };

  return {
    control: (baseStyles, state) => ({
      ...baseStyles,
      ...commonFontStyles,
      height: '40px',
      borderRadius: '6px',
      borderWidth: '1px',
      borderStyle: 'solid',
      padding: '10px 14px',
      pointerEvents: 'auto',
      cursor: state.isDisabled ? 'not-allowed' : 'pointer',
      ...sx({
        backgroundColor: 'neutral100',
        borderColor: state.isDisabled
          ? 'neutral500'
          : state.menuIsOpen || state.isFocused
          ? variantSx.$active.borderColor
          : 'neutral500',
      }),
      '&:hover': {
        ...sx({
          borderColor: state.isDisabled ? variantSx.borderColor : 'primary600',
        }),
      },
      boxShadow: state.isFocused ? '0px 0px 0px 3px #B2D5FF' : 'none',
    }),
    dropdownIndicator: (baseStyles) => ({
      ...baseStyles,
      padding: '0px',
    }),
    valueContainer: (baseStyles, state) => ({
      ...baseStyles,
      cursor: state.isDisabled ? 'not-allowed' : 'pointer',
      padding: '0px',
    }),
    input: (baseStyles, state) => ({
      ...baseStyles,
      cursor: state.isDisabled ? 'not-allowed' : 'pointer',
      padding: '0px',
      margin: '0px',
    }),
    singleValue: (baseStyles, state) => ({
      ...baseStyles,
      ...sx({
        color: state.isDisabled ? 'neutral700' : variantSx.color,
        fontWeight: variantSx.fontWeight,
      }),
    }),
    placeholder: (baseStyles, state) => ({
      ...baseStyles,
      ...sx({
        color: state.isDisabled ? 'neutral700' : variantSx.$placeholder.color,
        fontWeight: variantSx.fontWeight,
      }),
    }),
    menu: (baseStyles) => ({
      ...baseStyles,
      border: 'none',
      borderRadius: '6px',
      boxShadow: '0px 1px 2px 0px rgba(16, 24, 40, 0.04)',
      ...sx({
        backgroundColor: 'neutral100',
      }),
    }),
    menuList: (baseStyles) => ({
      ...baseStyles,
      maxHeight: '168px',
      margin: '4px 4px 4px 0',
      padding: '0px',

      // scrollbar styles
      '::-webkit-scrollbar': {
        width: '6px',
        marginRight: '4px',
      },
      '::-webkit-scrollbar-track': {
        ...sx({ backgroundColor: 'neutral500' }),
        borderRadius: '3px',
        border: 'none',
      },
      '::-webkit-scrollbar-thumb': {
        ...sx({ backgroundColor: 'gray50' }),
        borderRadius: '3px',
      },
    }),
    option: (baseStyles, state) => ({
      ...baseStyles,
      ...commonFontStyles,
      width: 'calc(100% - 4px)',
      ...sx({
        backgroundColor: state.isSelected
          ? 'primary50'
          : state.isFocused
          ? 'neutral500'
          : baseStyles.backgroundColor,
        padding: '10px 16px',
      }),
    }),
  };
};

export interface SelectOptionType<T = string | number> {
  value: T | null;
  label: string;
}

export interface SelectProps {
  options: SelectOptionType<number | string>[];
  placeholder?: string;
  label?: string;
  disabled?: boolean;
  onChange?: (selectedOption: { value: string; label: string }) => void;
  managedValue?: string | number | null;
  className?: string;
  variant?: keyof BaseTheme['select'];
  tooltip?: string;
  labelSx?: SxProp;
}

const Select = ({
  options,
  placeholder = 'Select',
  label,
  disabled,
  onChange,
  className,
  managedValue,
  variant = 'form',
  tooltip,
  labelSx,
}: SelectProps) => {
  const variantStyles = useSelectStyles(variant);

  const id = label ? label.toLowerCase().replace(/[^a-z0-g]/g, '-') : undefined;

  const sx = useSx();

  const labelStyles = {
    fontSize: '14px',
    display: 'flex',
    fontWeight: '500',
    lineHeight: '20px',
    letterSpacing: '-0.1px',
    ...sx({ color: disabled ? 'neutral700' : 'gray600' }),
    paddingBottom: '6px',
    gap: '8px',
    alignItems: 'center',
    ...labelSx,
  };

  return (
    <div className={className}>
      {label && (
        <label htmlFor={id} style={labelStyles}>
          {label}
          {tooltip && (
            <Tooltip label={tooltip}>
              <IconCircleInfo color="gray50" height={15} width={15} />
            </Tooltip>
          )}
        </label>
      )}
      <ReactSelect
        id={id}
        isDisabled={disabled}
        // className={styles.select}
        components={{
          DropdownIndicator: CustomDropdownIndicator,
          IndicatorSeparator: () => null,
        }}
        theme={(theme) => ({
          ...theme,
        })}
        options={options}
        placeholder={placeholder}
        styles={variantStyles}
        value={options.find((option) => option.value === managedValue) || null}
        onChange={onChange}
      />
    </div>
  );
};

export default Select;
