import React from 'react';
import CostCalculatorFormSection from '../common/CostCalculatorFormSection';
import FormInputContainer from '../common/FormInputContainer';
import PathNumberInput from '../common/PathNumberInput';
import { useSx } from 'dripsy';
import { updateFormInputs } from '../../../../../../state/customerCostReports';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../../../state/store';
import {
  Accordion,
  AccordionSection,
} from '../../../../../../aurora/components/Accordion/Accordion';
import ShippingCostsTable from './ShippingCostsTable';
import { CostItem } from '../../../../../../state/customerCostReports/types';
import styles from '../Form.module.scss';

const ShippingSection = () => {
  const sx = useSx();
  const dispatch = useDispatch();
  const initialData = useSelector(
    (state: RootState) =>
      state.customerCostReports.selectedReport?.estimateInputs
  ) as any;
  const initialTrips = initialData?.shippingInputs?.trips || [];
  const currentTrips = useSelector(
    (state: RootState) =>
      state.customerCostReports.formInputs.estimateInputs.shippingInputs
        .trips || []
  );

  const getToggleSwitchProps = (tripName: string) => ({
    defaultValue: initialTrips.some(
      (trip: CostItem<string>) => trip.name === tripName
    ),
    onSwitchOff: () => {
      const updatedTrips = currentTrips.filter(
        (trip) => trip.name !== tripName
      );
      dispatch(
        updateFormInputs({
          path: 'estimateInputs.shippingInputs.trips',
          value: updatedTrips,
        })
      );
    },
  });

  const sections: AccordionSection[] = [
    {
      header: (
        <div
          style={sx({
            fontFamily: 'Inter, sans-serif',
            color: 'gray50',
            fontWeight: '500',
            lineHeight: '20px',
            fontSize: '12px',
          })}
        >
          View rates
        </div>
      ),
      body: <ShippingCostsTable />,
      tableSection: true,
    },
  ];

  return (
    <CostCalculatorFormSection
      title={'Shipping Costs'}
      subtitle={
        'Calculate total shipping expenses with the option to select standard carrier rates based on weight and dimensions.'
      }
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          gap: '20px',
          width: '100%',
        }}
      >
        <div style={{ display: 'flex', gap: '24px' }}>
          <FormInputContainer
            title="Trip 1"
            toggleSwitch={getToggleSwitchProps('trip1')}
            inputs={(disabled: boolean) => (
              <PathNumberInput
                path="estimateInputs.shippingInputs.trips"
                label="Cost"
                showIncrement
                disabled={disabled}
                labelClassName={styles.label}
                type="dollar"
                arrayItemName="trip1"
                placeholder="$0"
                minNum={0}
              />
            )}
          />
          <FormInputContainer
            title="Trip 2"
            toggleSwitch={getToggleSwitchProps('trip2')}
            inputs={(disabled: boolean) => (
              <PathNumberInput
                path="estimateInputs.shippingInputs.trips"
                label="Cost"
                showIncrement
                disabled={disabled}
                labelClassName={styles.label}
                type="dollar"
                arrayItemName="trip2"
                placeholder="$0"
                minNum={0}
              />
            )}
          />
          <FormInputContainer
            title="Trip 3"
            toggleSwitch={getToggleSwitchProps('trip3')}
            inputs={(disabled: boolean) => (
              <PathNumberInput
                path="estimateInputs.shippingInputs.trips"
                label="Cost"
                showIncrement
                disabled={disabled}
                labelClassName={styles.label}
                type="dollar"
                arrayItemName="trip3"
                placeholder="$0"
                minNum={0}
              />
            )}
          />
        </div>
        <div>
          <Accordion sections={sections} />
        </div>
      </div>
    </CostCalculatorFormSection>
  );
};

export default ShippingSection;
