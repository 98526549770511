import React, { useState, ComponentType, useEffect } from 'react';
import { IconChevronDown } from '../../icons/IconChevronDown';
import { IconChevronUp } from '../../icons/IconChevronUp';
import { Flex, Pressable, Text } from 'dripsy';
import {
  useFloating,
  shift,
  autoUpdate,
  flip,
  offset,
} from '@floating-ui/react';
import { useSx } from 'dripsy';
import { IconClose } from '../../icons';
import {
  popoverStyles,
  tagStyles,
  tagTextStyles,
  labelStyles,
  filterInputStyles,
} from './filterStyles';
import styles from './Filter.module.scss';

// functions that can be used by the popover content
export interface PopoverContentProps<FilterType> {
  onSubmit: (filter: FilterType) => void;
  onCancel: () => void;
  onClear: () => void;
}

export interface FilterProps<FilterType> {
  label: string;
  showLabelFiltered?: boolean;
  popoverContent: ComponentType<PopoverContentProps<FilterType>>;
  onSubmit: (filter: FilterType) => void;
  onClear: () => void;
  toFiltersSummary: (filter: FilterType) => string;
  className?: string;
}
const Filter = <FilterType,>({
  label,
  showLabelFiltered = true,
  popoverContent: PopoverContent,
  onSubmit,
  onClear,
  toFiltersSummary,
  className,
}: FilterProps<FilterType>) => {
  const sx = useSx();
  const [open, setOpen] = useState(false);
  const [filtersSummary, setFiltersSummary] = useState<null | string>(null);

  const { refs, floatingStyles } = useFloating({
    middleware: [shift(), flip(), offset(10)],
    whileElementsMounted: autoUpdate,
  });

  useEffect(() => {
    function handleClickOutside(event: any) {
      if (
        refs.floating.current &&
        !refs.floating.current.contains(event.target) &&
        refs.reference.current &&
        //@ts-ignore
        !refs.reference.current.contains(event.target)
      ) {
        setOpen(false);
      }
    }
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [refs.floating]);

  const isFiltered = filtersSummary !== null;
  const Chevron = open ? IconChevronUp : IconChevronDown;
  const borderColor = open ? 'primary600' : 'neutral700';
  const textColor = isFiltered ? 'gray50' : 'gray600';

  const submitPopover = (filter: FilterType) => {
    const summary = toFiltersSummary(filter);
    setFiltersSummary(summary);
    setOpen(false);
    onSubmit(filter);
  };
  const clearPopover = () => {
    setFiltersSummary(null);
    setOpen(false);
    onClear();
  };

  const popover = open ? (
    <div
      ref={refs.setFloating}
      style={{
        ...floatingStyles,
        ...sx(popoverStyles),
      }}
    >
      <PopoverContent
        onSubmit={submitPopover}
        onCancel={() => setOpen(false)}
        onClear={clearPopover}
      />
    </div>
  ) : null;

  // tag that shows up on the input when a filter is applied
  const summaryTag = isFiltered ? (
    <Flex sx={tagStyles}>
      <Text sx={tagTextStyles}>{filtersSummary}</Text>
      <Pressable onPress={clearPopover} sx={{ color: 'primary600' }}>
        <IconClose width={12} height={12} strokeWidth={3} />
      </Pressable>
    </Flex>
  ) : null;

  // if !showLabelFiltered, don't show label when filter is set
  const labelComponent =
    isFiltered && !showLabelFiltered ? null : (
      <Text
        sx={{
          ...labelStyles,
          color: textColor,
        }}
      >
        {label}
      </Text>
    );

  return (
    <div className={`${styles.filter} ${className}`}>
      <div
        ref={refs.setReference}
        onClick={() => setOpen(!open)}
        style={sx({
          ...filterInputStyles,
          borderColor,
          color: textColor,
        })}
      >
        {summaryTag}
        {labelComponent}
        <Chevron width={20} height={20} />
      </div>

      {popover}
    </div>
  );
};

export default Filter;
