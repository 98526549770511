import React from 'react';
import { Button } from '../../aurora/components/Button/Button';
import { ShipmentEntity } from '../../state/shipments/types';
import DownloadCsvButton from '../../pages/shipments/components/DownloadCsvButton';

interface Props {
  shipment: ShipmentEntity | undefined;
  closeSlideout: () => void;
}
const SlideoutFooter = ({ shipment, closeSlideout }: Props) => {
  if (!shipment) {
    return null;
  }

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        gap: '16px',
        width: '100%',
      }}
    >
      <Button
        variant="secondary"
        label="Close"
        onClick={closeSlideout}
        sx={{ flex: 1 }}
      />
      <DownloadCsvButton
        shipments={[shipment]}
        text="Download Shipment"
        variant="primary"
        hideIcon={true}
        sx={{ flex: 1 }}
      />
    </div>
  );
};

export default SlideoutFooter;
