import React, { CSSProperties } from 'react';
import {
  DeviationStage,
  EventEntity,
  EventStatusesEnum,
  EventTypesEnum,
  TemperatureExcursionDetails,
  readableType,
} from '../../state/events/types';
import { Text } from '../../aurora/typography/Text/Text';
import EventStatusLabel from '../../pages/events/components/EventStatusLabel';
import {
  IconCalendar,
  IconClock,
  IconNumber,
  IconTemperature,
} from '../../aurora/icons';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch } from '../../state/store';
import { Button } from '../../aurora/components/Button/Button';
import { markReviewedAction } from '../../state/events';
import useAxiosPrivate from '../../hooks/useAxiosPrivate';
import { selectAuth } from '../../state/auth';
import { SxProp, useSx } from 'dripsy';
import { formatDateTime } from '../../utils/dateUtil';
import { formatDuration, intervalToDuration } from 'date-fns';

interface Props {
  events: EventEntity[];
}
const EventsSection = ({ events }: Props) => {
  const dispatch = useDispatch<AppDispatch>();
  const axiosPrivate = useAxiosPrivate();
  const auth = useSelector(selectAuth);
  const sx = useSx();

  const textStyle: SxProp = {
    color: 'gray50',
    fontWeight: '400',
  };

  const dataStyle: CSSProperties = {
    display: 'flex',
    gap: '6px',
    justifyContent: 'flex-start',
    alignItems: 'center',
    marginBottom: '16px',
  };

  return (
    <div>
      {events.map((event, idx) => {
        let description = '';
        let recordedTemp: number | undefined;
        if (event.eventType === EventTypesEnum.TEMP_EXCURSION) {
          const details = event.eventDetails as
            | TemperatureExcursionDetails
            | undefined;
          if (details?.deviationStage === DeviationStage.POST_COOLDOWN) {
            description = 'Device experienced a steady state excursion.';
          }
          recordedTemp = details?.deviationTempC;
        }

        const duration =
          event.eventEnd !== undefined
            ? formatDuration(
                intervalToDuration({
                  start: new Date(event.eventStart),
                  end: new Date(event.eventEnd),
                }),
                {
                  format: ['weeks', 'days', 'hours', 'minutes'],
                }
              )
            : '-';

        return (
          <div key={event.pid}>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                marginBottom: '16px',
              }}
            >
              <Text size="m" sx={{ fontWeight: '500' }}>
                {readableType[event.eventType]}
              </Text>
              <EventStatusLabel status={event.status} />
            </div>
            {description !== '' ? (
              <Text size="m" sx={{ ...textStyle, marginBottom: '16px' }}>
                {description}
              </Text>
            ) : null}
            <div>
              <div style={dataStyle}>
                <IconNumber width={16} height={16} color="gray50" />
                <Text size="s" sx={textStyle}>
                  Event ID: {event.pid}
                </Text>
              </div>
              <div style={dataStyle}>
                <IconCalendar width={16} height={16} color="gray50" />
                <Text size="s" sx={textStyle}>
                  Date: {formatDateTime(event.eventStart)}
                </Text>
              </div>
              <div style={dataStyle}>
                <IconClock width={16} height={16} color="gray50" />
                <Text size="s" sx={textStyle}>
                  Duration: {duration}
                </Text>
              </div>
              {recordedTemp !== undefined ? (
                <div style={dataStyle}>
                  <IconTemperature width={16} height={16} color="gray50" />
                  <Text size="s" sx={textStyle}>
                    Recorded Temperature: {`${recordedTemp.toFixed(2)}°C`}
                  </Text>
                </div>
              ) : null}
            </div>
            {event.status === EventStatusesEnum.NEW ? (
              <Button
                variant="secondary"
                label="Mark As Reviewed"
                onClick={() =>
                  dispatch(
                    markReviewedAction({
                      axios: axiosPrivate,
                      auth,
                      eventIds: [event._id],
                    })
                  )
                }
              />
            ) : null}
            {idx !== events.length - 1 ? (
              <div
                style={sx({
                  margin: '24px 0',
                  borderBottom: '1px solid',
                  borderColor: 'neutral700',
                })}
              />
            ) : null}
          </div>
        );
      })}
    </div>
  );
};

export default EventsSection;
