import { AxiosInstance } from 'axios';
import { genericFetch, genericPost } from './api';
import { Auth } from '../state/auth/types';
import {
  ShipmentEntity,
  ShipmentSnapshotEntity,
} from '../state/shipments/types';

const ARTYC_SHIPMENT_URL = '/artyc/shipments';
const COMPANY_SHIPMENT_URL = (auth: Auth | null) =>
  `/api/v1/company/${auth?.companyId}/shipments`;

interface GetShipmentMetricResponse {
  metric: string;
}
const ShipmentsApi = {
  fetchMetric: async (
    axios: AxiosInstance,
    auth: Auth | null,
    endpoint: string,
    rangeStartDate: string | undefined,
    rangeEndDate: string | undefined,
    companyIdsFilter: string[] | undefined,
    forCompanyId: string | undefined
  ): Promise<string> => {
    const response: GetShipmentMetricResponse = await genericFetch(
      axios,
      auth,
      `/artyc/shipments/metrics/${endpoint}`,
      `/api/v1/company/${forCompanyId}/shipments/metrics/${endpoint}`,
      {
        rangeStartDate,
        rangeEndDate,
        companyIds: companyIdsFilter,
      }
    );
    return response.metric;
  },

  getHoursWithoutExcursion: async (
    axios: AxiosInstance,
    auth: Auth | null,
    rangeStartDate: string | undefined,
    rangeEndDate: string | undefined,
    companyIdsFilter: string[] | undefined,
    forCompanyId: string | undefined
  ): Promise<string> => {
    return ShipmentsApi.fetchMetric(
      axios,
      auth,
      'hours-without-excursion',
      rangeStartDate,
      rangeEndDate,
      companyIdsFilter,
      forCompanyId
    );
  },

  getMaxDeviceUtilization: async (
    axios: AxiosInstance,
    auth: Auth | null,
    rangeStartDate: string | undefined,
    rangeEndDate: string | undefined,
    companyIdsFilter: string[] | undefined,
    forCompanyId: string | undefined
  ): Promise<string> => {
    return ShipmentsApi.fetchMetric(
      axios,
      auth,
      'max-device-utilization',
      rangeStartDate,
      rangeEndDate,
      companyIdsFilter,
      forCompanyId
    );
  },

  getTotalShipments: async (
    axios: AxiosInstance,
    auth: Auth | null,
    rangeStartDate: string | undefined,
    rangeEndDate: string | undefined,
    companyIdsFilter: string[] | undefined,
    forCompanyId: string | undefined
  ): Promise<string> => {
    return ShipmentsApi.fetchMetric(
      axios,
      auth,
      'total-shipments',
      rangeStartDate,
      rangeEndDate,
      companyIdsFilter,
      forCompanyId
    );
  },
  downloadShipmentCsv: async (
    axios: AxiosInstance,
    auth: Auth | null,
    shipmentIds: string[]
  ) => {
    // TODO: type
    const response = await genericPost<any>(
      axios,
      auth,
      `${ARTYC_SHIPMENT_URL}/csvs`,
      `${COMPANY_SHIPMENT_URL(auth)}/csvs`,
      { shipmentIds },
      { responseType: 'blob' }
    );
    return response;
  },
  downloadLogCsv: async (
    axios: AxiosInstance,
    auth: Auth | null,
    shipmentIds: string[]
  ) => {
    // TODO: type
    const response = await genericPost<any>(
      axios,
      auth,
      `${ARTYC_SHIPMENT_URL}/logs`,
      `${COMPANY_SHIPMENT_URL(auth)}/logs`,
      { shipmentIds },
      { responseType: 'blob' }
    );
    return response;
  },

  getShipmentSnapshots: async (
    axios: AxiosInstance,
    auth: Auth | null,
    shipmentId: string
  ) => {
    const response: ShipmentSnapshotEntity[] = await genericFetch(
      axios,
      auth,
      `${ARTYC_SHIPMENT_URL}/${shipmentId}/snapshots`,
      `${COMPANY_SHIPMENT_URL(auth)}/${shipmentId}/snapshots`
    );
    return response;
  },
  getShipment: async (
    axios: AxiosInstance,
    auth: Auth | null,
    shipmentId: string
  ): Promise<ShipmentEntity> => {
    const response: ShipmentEntity = await genericFetch(
      axios,
      auth,
      `${ARTYC_SHIPMENT_URL}/${shipmentId}`,
      `${COMPANY_SHIPMENT_URL(auth)}/${shipmentId}`
    );
    return response;
  },
};

export default ShipmentsApi;
