import React from 'react';
import DataTableContainer from '../../components/table/DataTableContainer';
import { DeviceEntity } from '../../state/devices/types';
import {
  loadDevices,
  resetPage,
  setCurrentPage,
  sortDevices,
} from '../../state/devices';
import DeviceEditButton from './components/DeviceEditButton';
import PageHeader from '../../components/layout/PageHeader';
import {
  ColumnsConfig,
  EmptyConfig,
  TopConfig,
} from '../../components/table/tableConfig';
import { IconCube } from '../../aurora/icons';
import { isArtycAdmin, selectAuth } from '../../state/auth';
import AddDeviceButton from './components/AddDeviceButton';
import CompanyFilter from '../../components/filters/CompanyFilter/CompanyFilter';
import DeviceTypeFilter from '../../components/filters/DeviceTypeFilter/DeviceTypeFilter';
import { setDeviceTypesFilter, setCompanyIdsFilter } from '../../state/devices';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../state/store';
import DeviceSearch from './components/DeviceSearch';
import styles from '../page.module.scss';
import MobileCompanyDeviceFilter from '../../components/filters/MobileChecklistFilter/MobileCompanyDeviceFilter';

const DevicesPage = () => {
  const dispatch = useDispatch();
  const auth = useSelector(selectAuth);

  const { deviceTypes, companyIds } = useSelector(
    (state: RootState) => state.devices.selectedFilters || {}
  );

  const topConfig: TopConfig = {
    text: 'Devices',
    action: '',
    // right now we only filter on these for artyc admin
    additionalBars: [
      <>
        <DeviceSearch />
        <div className={styles.filterGroup}>
          <DeviceTypeFilter
            setDeviceTypesAction={setDeviceTypesFilter}
            filteredDeviceTypes={deviceTypes}
          />
          {isArtycAdmin(auth) && (
            <CompanyFilter
              setCompanyIdsAction={(companyIds) =>
                dispatch(setCompanyIdsFilter(companyIds))
              }
              filteredCompanyIds={companyIds}
            />
          )}
          <MobileCompanyDeviceFilter
            setDeviceTypesAction={(deviceTypes) =>
              dispatch(setDeviceTypesFilter(deviceTypes))
            }
            filteredDeviceTypes={deviceTypes}
            setCompanyIdsAction={(companyIds) =>
              dispatch(setCompanyIdsFilter(companyIds))
            }
            filteredCompanyIds={companyIds}
          />
        </div>
      </>,
    ],
  };

  const emptyConfig: EmptyConfig = {
    icon: IconCube,
    title: 'No devices found',
    body: "Once devices are detected, they'll show up here",
  };
  // TODO(test): test that company only shows for artyc admin
  const columnsConfig: ColumnsConfig<DeviceEntity> = {
    columns: [
      {
        title: 'Device Model',
        property: 'deviceType',
      },
      {
        title: 'Serial #',
        property: 'serialNumber',
      },
      {
        title: 'Company',
        property: 'companyName',
        showColumn: isArtycAdmin(auth),
      },
    ],
    actionColumn: (entity: DeviceEntity) => (
      <DeviceEditButton device={entity} />
    ),
  };

  return (
    <>
      <PageHeader headingText="Devices" action={<AddDeviceButton />} />
      <DataTableContainer
        topConfig={topConfig}
        emptyConfig={emptyConfig}
        columnsConfig={columnsConfig}
        sortAction={sortDevices}
        loadDataAction={loadDevices}
        pageAction={setCurrentPage}
        stateName="devices"
        resetAction={resetPage}
      />
    </>
  );
};

export default DevicesPage;
