import React from 'react';
import { TopConfig } from './tableConfig';
import { Text } from '../../aurora/typography/Text/Text';
import styles from './DataTableTopBar.module.scss';
interface Props {
  topConfig: TopConfig;
}
const DataTableTopBar = ({ topConfig }: Props) => {
  const { text, action, additionalBars } = topConfig;

  return (
    <div>
      <div
        className={`${styles.tableHeader} ${styles.topRow} ${styles.tableHeaderSeparator}`}
      >
        <Text size="xl" sx={{ fontWeight: '600' }}>
          {text}
        </Text>
        {action}
      </div>
      {additionalBars?.map((bar, i) => (
        <div
          className={`${styles.tableHeader} ${styles.tableHeaderSeparator}`}
          key={`top-bar-${i}`}
        >
          {bar}
        </div>
      ))}
    </div>
  );
};

export default DataTableTopBar;
