import { useNavigate } from 'react-router-dom';
import { useCallback } from 'react';
import axios from '../api/axios';
import jwt_decode from 'jwt-decode';
import { useDispatch } from 'react-redux';
import { setAuth } from '../state/auth';

let isRefreshing = false;
interface RefreshPromise {
  resolve: (value: any) => void;
  reject: (value: any) => void;
}
let refreshPromisesQueue: RefreshPromise[] = [];

const useRefreshToken = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const refresh = useCallback(async () => {
    if (isRefreshing) {
      return new Promise((resolve, reject) => {
        refreshPromisesQueue.push({ resolve, reject });
      });
    }

    isRefreshing = true;

    try {
      const response = await axios.get('/auth/refresh', {
        withCredentials: true,
      });

      const { accessToken } = response.data;
      const { userInfo } = jwt_decode<any>(accessToken);

      dispatch(setAuth({ ...userInfo, accessToken }));
      refreshPromisesQueue.forEach(({ resolve }) => resolve(accessToken));
      return accessToken;
    } catch (error: any) {
      refreshPromisesQueue.forEach(({ reject }) => reject(error));
      if (
        error.response &&
        (error.response.status === 401 || error.response.status === 403)
      ) {
        // Status is either 401 or 403, so redirect to login page
        navigate('/login');
      } else {
        // Handle other errors differently
        console.error(error);
      }
    } finally {
      isRefreshing = false;
      refreshPromisesQueue = [];
    }
  }, [navigate]);

  return refresh;
};

export default useRefreshToken;
