import React from 'react';
import { Path } from 'react-native-svg';

import { IconBase, IconProps } from './IconBase';

export const IconArrowForward: React.FunctionComponent<IconProps> = ({
  ...props
}) => (
  <IconBase {...props}>
    <Path
      d="M5 16V15C5 13.3431 6.34356 12 8.00093 12H18.004M15.0031 16L18.297 12.7071C18.6877 12.3166 18.6877 11.6834 18.297 11.2929L15.0031 8"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
    />
  </IconBase>
);
