import React from 'react';
import { Button } from '../../../aurora/components/Button/Button';
import { IconDownload } from '../../../aurora/icons';
import useAxiosPrivate from '../../../hooks/useAxiosPrivate';
import { useDispatch, useSelector } from 'react-redux';
import { selectAuth } from '../../../state/auth';
import { ShipmentEntity } from '../../../state/shipments/types';
import { BaseTheme } from '../../../aurora/theme/baseTheme';
import { downloadCsvAction } from '../../../state/shipments';
import { AppDispatch } from '../../../state/store';
import { SxProp } from 'dripsy';

interface Props {
  shipments: ShipmentEntity[];
  text: string;
  variant?: keyof BaseTheme['buttons'];
  hideIcon?: boolean;
  sx?: SxProp;
  fullLog?: boolean;
}
const DownloadCsvButton = ({
  shipments,
  text,
  variant,
  hideIcon,
  sx,
  fullLog,
}: Props) => {
  const axiosPrivate = useAxiosPrivate();
  const auth = useSelector(selectAuth);
  const dispatch = useDispatch<AppDispatch>();

  const downloadCsv = () => {
    dispatch(
      downloadCsvAction({
        axios: axiosPrivate,
        auth,
        shipments,
        fullLog: fullLog || false,
      })
    );
  };

  return (
    <Button
      label={text}
      sx={sx}
      iconRight={hideIcon ? undefined : IconDownload}
      variant={variant || 'ghostPrimary'}
      onClick={downloadCsv}
    />
  );
};

export default DownloadCsvButton;
