import React, { ReactNode, useEffect, useState } from 'react';
import {
  ColumnsConfig,
  SlideoutConfig,
  EmptyConfig,
  PaginationConfig,
  SortConfig,
  TopConfig,
} from './tableConfig';
import DataTableHeaderRow from './DataTableHeaderRow';
import DataTableRow from './DataTableRow';
import DataTablePagination from './DataTablePagination';
import BulkActionBar from './BulkActionBar';
import DataTableTopBar from './DataTableTopBar';
import EmptyTable from './EmptyTable';
import TableError from './TableError';
import TableNotice from './TableNotice';
import LoadingSpinner from '../layout/LoadingSpinner';
import { Text } from '../../aurora/typography/Text/Text';
import styles from './DataTable.module.scss';

export interface DataTableProps<T> {
  data: T[];
  topConfig: TopConfig;
  emptyConfig: EmptyConfig;
  columnsConfig: ColumnsConfig<T>;
  paginationConfig?: PaginationConfig;
  sortConfig?: SortConfig<T>;
  slideoutConfig?: SlideoutConfig<T>;
  loading: boolean;
  error?: Error;
}

function getId<T extends object>(obj: T): string {
  if ('_id' in obj) {
    return obj._id as string;
  }
  return '';
}

const DataTable = <T extends object>({
  data,
  columnsConfig,
  topConfig,
  emptyConfig,
  paginationConfig,
  sortConfig,
  slideoutConfig,
  loading,
  error,
}: DataTableProps<T>) => {
  // bulk action management
  const [selectedRows, setSelectedRows] = useState<Record<string, T>>({});
  const [openSlideoutEntity, setOpenSlideoutEntity] = useState<undefined | T>(
    undefined
  );

  useEffect(() => {
    setOpenSlideoutEntity(slideoutConfig?.initialOpenEntity);
  }, [slideoutConfig?.initialOpenEntity]);

  const allSelected = data.reduce(
    (all, curr) => all && selectedRows[getId(curr)] !== undefined,
    true
  );
  const selectAll = (checked: boolean) => {
    if (checked) {
      const newSelected = Object.assign({}, selectedRows);
      data.forEach((d) => (newSelected[getId(d)] = d));
      setSelectedRows(newSelected);
    } else {
      setSelectedRows({});
    }
  };
  const selectRow = (entity: T) => (checked: boolean) => {
    const newSelected = Object.assign({}, selectedRows, {
      [getId(entity)]: checked ? entity : undefined,
    });
    setSelectedRows(newSelected);
  };

  const showData = data.length !== 0 && !loading && error === undefined;

  let body: ReactNode;

  if (showData) {
    // clicking the same one closes it, clicking a different one opens that one
    const toggleSlideout = (entity: T) =>
      openSlideoutEntity === entity
        ? setOpenSlideoutEntity(undefined)
        : setOpenSlideoutEntity(entity);
    const showSlideout =
      slideoutConfig !== undefined &&
      (slideoutConfig.showSlideout === undefined ||
        slideoutConfig.showSlideout);

    body = data.map((entity, i) => {
      const onClick = showSlideout ? () => toggleSlideout(entity) : undefined;

      return (
        <DataTableRow
          columnsConfig={columnsConfig}
          entity={entity}
          rowSelected={selectedRows[getId(entity)] !== undefined}
          onSelectRow={selectRow(entity)}
          key={`data-table-row-${i}`}
          onClick={onClick}
        />
      );
    });
  } else {
    body = null;
  }

  let notice: ReactNode;

  if (loading) {
    const noticeBody = (
      <>
        <div className={styles.noticeBody}>
          <LoadingSpinner height={32} width={32} />
        </div>
        <Text size="s" sx={{ textAlign: 'center', color: 'gray300' }}>
          {'Please wait...'}
        </Text>
      </>
    );

    notice = <TableNotice title={'Loading'} body={noticeBody} />;
  } else if (error !== undefined) {
    notice = <TableError />;
  } else if (!showData) {
    notice = <EmptyTable emptyConfig={emptyConfig} />;
  } else {
    notice = null;
  }

  const footer =
    showData && paginationConfig ? (
      <DataTablePagination
        currentPage={paginationConfig.currentPage}
        totalPages={paginationConfig.totalPages}
        onPage={paginationConfig.onPage}
      />
    ) : null;

  const closeSlideout = () => setOpenSlideoutEntity(undefined);
  const slideout =
    slideoutConfig !== undefined
      ? slideoutConfig.slideout(openSlideoutEntity, true, closeSlideout)
      : null;

  return (
    <div className={styles.tableContainer}>
      <DataTableTopBar topConfig={topConfig} />
      <BulkActionBar
        selected={Object.values(selectedRows).filter(
          (row) => row !== undefined
        )}
        columnsConfig={columnsConfig}
        clearAll={() => selectAll(false)}
      />
      <div className={styles.tableContentWrapper}>
        <table className={styles.table}>
          <thead>
            <DataTableHeaderRow
              showData={showData}
              columnsConfig={columnsConfig}
              sortConfig={sortConfig}
              allSelected={allSelected}
              onSelectAll={selectAll}
            />
          </thead>
          <tbody>{body}</tbody>
        </table>
      </div>
      {notice}
      {footer}
      {slideout}
    </div>
  );
};

export default DataTable;
