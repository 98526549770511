import React from 'react';
import ModalAction from '../../../components/table/ModalAction';
import { UserEntity } from '../../../state/users/types';
import { loadUsers } from '../../../state/users';
import UserForm from '../../../components/UserForm';
import UsersApi from '../../../api/usersApi';
import { useSelector } from 'react-redux';
import { selectAuth } from '../../../state/auth';
import useAxiosPrivate from '../../../hooks/useAxiosPrivate';
import { IconEdit } from '../../../aurora/icons';

interface Props {
  user: UserEntity;
}
const UserEditButton = ({ user }: Props) => {
  const auth = useSelector(selectAuth);
  const axiosPrivate = useAxiosPrivate();

  return (
    <ModalAction
      modalTitle="Edit User"
      loadDataAction={loadUsers}
      stateName="users"
      icon={IconEdit}
      modalContent={(onDataChange, onClose) => (
        <UserForm
          forUser={user}
          onSubmit={async (data) => {
            await UsersApi.updateUser(axiosPrivate, auth, user._id, data);
            await onDataChange();
          }}
          successMessage="User updated successfully"
          submitText="Save Changes"
          onClose={onClose}
          onDelete={
            user._id !== auth?._id
              ? async () => {
                  await UsersApi.deleteUser(axiosPrivate, auth, user._id);
                  await onDataChange();
                }
              : undefined
          }
        />
      )}
    />
  );
};

export default UserEditButton;
