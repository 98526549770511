import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Switch from '../../../aurora/components/Switch/Switch';
import { isArtycAdmin, selectAuth } from '../../../state/auth';
import { toggleViewAsCustomer } from '../../../state/shipments';
import { RootState } from '../../../state/store';
import styles from './ViewAsCustomer.module.scss';

export const ViewAsCustomerToggle = () => {
  const dispatch = useDispatch();
  const viewAsCustomer = useSelector(
    (state: RootState) => state.shipments.viewAsCustomer
  );
  const auth = useSelector(selectAuth);

  if (!isArtycAdmin(auth)) {
    return null;
  }

  return (
    <div className={styles.container}>
      <span>View Graphs as Customer</span>
      <Switch
        isSwitchOn={viewAsCustomer}
        onChange={() => {
          dispatch(toggleViewAsCustomer());
        }}
      />
    </div>
  );
};
