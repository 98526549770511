import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import CustomerCostReportApi from '../../../../../../api/customerCostReportApi';
import useAxiosPrivate from '../../../../../../hooks/useAxiosPrivate';
import {
  setIndustryEstimates,
  updateFormInputs,
} from '../../../../../../state/customerCostReports';
import { CustomerCostReportEntity } from '../../../../../../state/customerCostReports/types';
import { reportToFormInputs } from '../../../../../../state/customerCostReports/utils/reportToFormInputs';
import { mkCustomerCostDollars } from '../../../../../../state/customerCostReports/utils/summaryUtils';
import { RootState } from '../../../../../../state/store';
import CostCalculatorFormSection from '../common/CostCalculatorFormSection';
import ComparisonCard from './ComparisonCard';
import styles from '../Form.module.scss';

interface IndustryOptionCardProps {
  estimate: CustomerCostReportEntity;
}
const IndustryOptionCard = ({ estimate }: IndustryOptionCardProps) => {
  const { industryComparisons, dynamicInputs } = useSelector(
    (state: RootState) => state.customerCostReports.formInputs
  );
  const dispatch = useDispatch();
  const onSwitch = (isOn: boolean, estimate: CustomerCostReportEntity) => {
    const newComparisons = isOn
      ? [...industryComparisons, estimate._id]
      : industryComparisons.filter((comparison) => comparison !== estimate._id);

    dispatch(
      updateFormInputs({ path: 'industryComparisons', value: newComparisons })
    );
  };

  const inputs = reportToFormInputs(estimate);
  const totalPerShipment = mkCustomerCostDollars(
    inputs.estimateInputs,
    dynamicInputs.numShipments
  ).totalPerShipment;
  const totalDollars = `$${totalPerShipment.toFixed(2)}`;

  return (
    <ComparisonCard
      key={estimate._id}
      title={estimate.industryEstimateName!}
      defaultSwitch={industryComparisons.includes(estimate._id)}
      onSwitch={(isOn) => onSwitch(isOn, estimate)}
      priceSet={true}
      input={
        <div>
          <div className={styles.label}>Price per shipment</div>
          <div className={styles.comparisonDollars}>{totalDollars}</div>
        </div>
      }
    />
  );
};

const IndustryComparisons = () => {
  const dispatch = useDispatch();
  const axios = useAxiosPrivate();
  const [loading, setLoading] = useState(false);

  const industryEstimates = useSelector(
    (state: RootState) => state.customerCostReports.industryEstimates
  );

  useEffect(() => {
    const fetchEstimates = async () => {
      try {
        setLoading(true);
        const estimates = await CustomerCostReportApi.getIndustryEstimates(
          axios
        );
        dispatch(setIndustryEstimates(estimates));
      } catch (error) {
        console.error('Error fetching report:', error);
      } finally {
        setLoading(false);
      }
    };

    if (industryEstimates.length === 0) {
      fetchEstimates();
    }
  }, []);

  return (
    <CostCalculatorFormSection title="Industry Estimates">
      {loading ? (
        <div>Loading...</div>
      ) : (
        industryEstimates.map((estimate) => (
          <IndustryOptionCard estimate={estimate} key={estimate._id} />
        ))
      )}
    </CostCalculatorFormSection>
  );
};

export default IndustryComparisons;
