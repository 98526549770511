import { ShipmentSnapshotEntity } from '../../state/shipments/types';
import { FeatureCollection, Feature, Point } from 'geojson';

interface CoordinateSnapshotGroup {
  coordinates: [number, number];
  timestampArray: string[];
  secSinceStartArray: number[];
  indexArray: number[];
  tempsArray: { sleeve: number; evap: number; hs: number }[];
}

interface PointGeoProps {
  timestampArray: string[];
  secSinceStartArray: number[];
  indexArray: number[];
  pointIndex: number;
}

export const groupSnapshotsByCoords = (
  segment: ShipmentSnapshotEntity[]
): CoordinateSnapshotGroup[] => {
  const coordinatesMap: { [key: string]: CoordinateSnapshotGroup } = {};

  segment.forEach((snapshot, index) => {
    const { location, timestamp, secSinceStart, temps } = snapshot;

    if (location && isValidLatLong(location.lat, location.long)) {
      // TODO: update this logic. It currently groups all snapshots that
      // share a location, but it should only group consecutive snapshots
      // that share a location.
      const coordinates: [number, number] = [location.long, location.lat];
      const coordKey = coordinates.join(',');

      if (!coordinatesMap[coordKey]) {
        coordinatesMap[coordKey] = {
          coordinates,
          timestampArray: [],
          secSinceStartArray: [],
          tempsArray: [],
          indexArray: [],
        };
      }
      coordinatesMap[coordKey].timestampArray.push(timestamp);
      coordinatesMap[coordKey].tempsArray.push(temps);
      coordinatesMap[coordKey].secSinceStartArray.push(secSinceStart);
      coordinatesMap[coordKey].indexArray.push(index);
    }
  });
  return Object.values(coordinatesMap);
};

export const isValidLatLong = (lat: number, long: number) => {
  return (
    lat >= -90 &&
    lat <= 90 &&
    long >= -180 &&
    long <= 180 &&
    // temporary condition until we change shipper behavior
    !(lat === 0 && long === 0)
  );
};

/* This function takes an array of snapshots that have been grouped 
by shared location and converts them into a geoJSON object. 

GeoJSON is an open standard file format for representing map data. 
Mapbox web services and APIs serve geospatial data as GeoJSON.

A GeoJSON object will have a `type` property and then other properties depending on the value of type.  Here's an exmaple of how we are using geoJSON:
{
       "type": "FeatureCollection",
       "features": [{
           "type": "Feature",
           "geometry": {
               "type": "Point",
               "coordinates": [102.0, 0.5]
           },
           "properties": {
               timestampArray: string[]; // array of the timestamps for all snapshots that happened at that point
               secSinceStartArray: number[]; // array of all secSinceStart values for snapshots that happened at that point
               indexArray: number[]; // array of the indicies of the snapshots that happened at that point
               pointIndex: number; // index of the Point in the sgement
           }
       }, {
           "type": "Feature",
           "geometry": {
               "type": "LineString",
               "coordinates": [
                   [102.0, 0.0],
                   [103.0, 1.0],
                   [104.0, 0.0],
                   [105.0, 1.0]
               ]
           },
           "properties": {
             // same as above
            }
          }]
}
*/
export const convertSnapshotGroupToGeoJSON = (
  aggregatedSnapshots: CoordinateSnapshotGroup[]
): FeatureCollection<Point, PointGeoProps> => {
  const features: Feature<Point, PointGeoProps>[] = aggregatedSnapshots.map(
    (snapshotGroup, index) => ({
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: snapshotGroup.coordinates,
      },
      properties: {
        timestampArray: snapshotGroup.timestampArray,
        secSinceStartArray: snapshotGroup.secSinceStartArray,
        indexArray: snapshotGroup.indexArray,
        tempsArray: snapshotGroup.tempsArray,
        pointIndex: index,
      },
    })
  );

  return {
    type: 'FeatureCollection',
    features,
  };
};
