import React from 'react';
import { useSx } from 'dripsy';
import { Text } from '../../../../../aurora/typography/Text/Text';

interface Props {
  subsections: { label: string; value: string }[];
}
const TotalSection = ({ subsections }: Props) => {
  const sx = useSx();

  return (
    <div
      style={sx({
        padding: '8px',
        marginTop: '8px',
        display: 'flex',
        flexDirection: 'column',
        gap: '4px',
      })}
    >
      {subsections.map((subsection) => (
        <div
          style={{ display: 'flex', justifyContent: 'space-between' }}
          key={subsection.label}
        >
          <Text size="s" sx={{ fontWeight: '400' }}>
            {subsection.label}
          </Text>
          <Text size="s" sx={{ fontWeight: '600', color: 'primary600' }}>
            {subsection.value}
          </Text>
        </div>
      ))}
    </div>
  );
};

export default TotalSection;
