import { AxiosInstance } from 'axios';
import { Auth } from '../state/auth/types';
import { genericFetch, genericPost, genericPatch, genericDelete } from './api';
import { ShippingProfileEntity } from '../state/shippingProfile/types';
import { FormDataType } from '../pages/shippingProfiles/components/ShippingProfileForm';

const ARTYC_SHIPPING_PROFILES_URL = '/artyc/shipping-profiles';
const COMPANY_SHIPPING_PROFILES_URL = (auth: Auth | null) =>
  `/api/v1/company/${auth?.companyId}/shipping-profiles`;

const ShippingProfilesApi = {
  fetchShippingProfiles: (
    axios: AxiosInstance,
    auth: Auth | null,
    companyIds: string[] | undefined
  ) => {
    return genericFetch<ShippingProfileEntity[]>(
      axios,
      auth,
      ARTYC_SHIPPING_PROFILES_URL,
      COMPANY_SHIPPING_PROFILES_URL(auth),
      {
        companyIds,
      }
    );
  },
  createShippingProfile: async (
    axios: AxiosInstance,
    auth: Auth | null,
    data: Omit<FormDataType, 'id'>
  ) => {
    return genericPost(
      axios,
      auth,
      `${ARTYC_SHIPPING_PROFILES_URL}`,
      `${COMPANY_SHIPPING_PROFILES_URL(auth)}`,
      data
    );
  },
  editShippingProfile: async (
    axios: AxiosInstance,
    auth: Auth | null,
    data: FormDataType
  ) => {
    const _id = data._id;
    return genericPatch(
      axios,
      auth,
      `${ARTYC_SHIPPING_PROFILES_URL}/${_id}`,
      `${COMPANY_SHIPPING_PROFILES_URL(auth)}/${_id}`,
      data
    );
  },
  deleteShippingProfile: async (
    axios: AxiosInstance,
    auth: Auth | null,
    _id: string
  ) => {
    return genericDelete(
      axios,
      auth,
      `${ARTYC_SHIPPING_PROFILES_URL}/${_id}`,
      `${COMPANY_SHIPPING_PROFILES_URL(auth)}/${_id}`
    );
  },
};

export default ShippingProfilesApi;
