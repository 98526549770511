import mapboxgl from 'mapbox-gl';
import { ShipmentToSnapshotsMap } from '../../state/shipments';
import { renderSegmentsOnMap } from './mapRendering';

export interface LegendData {
  segmentId: string;
  startColor: string;
  endColor: string;
}

export const initializeMap = (container: HTMLDivElement) => {
  const map = new mapboxgl.Map({
    container,
    style: 'mapbox://styles/mapbox/streets-v11',
    center: [0, 0],
    zoom: 2,
  });

  const popup = new mapboxgl.Popup({
    closeButton: false,
    closeOnClick: false,
  });

  return { map, popup };
};

export const setupMap = (
  map: mapboxgl.Map,
  popup: mapboxgl.Popup,
  segments: ShipmentToSnapshotsMap,
  legendDataTemp: LegendData[],
  setLegendData: (data: LegendData[]) => void
) => {
  map.on('load', () => {
    const allBounds = renderSegmentsOnMap(map, segments, legendDataTemp, popup);

    if (
      allBounds[0][0] !== Infinity &&
      allBounds[0][1] !== Infinity &&
      allBounds[1][0] !== -Infinity &&
      allBounds[1][1] !== -Infinity
    ) {
      map.fitBounds(allBounds, {
        padding: { top: 20, bottom: 40, left: 20, right: 20 },
      });
    }
    setLegendData(legendDataTemp);
  });
};
