import React from 'react';
import { useSx } from 'dripsy';
import { Text } from '../../../../../aurora/typography/Text/Text';

interface Props {
  title: string;
  costDollars: number;
}
const SubtotalSection = ({ title, costDollars }: Props) => {
  const sx = useSx();

  return (
    <div
      style={sx({
        display: 'flex',
        justifyContent: 'space-between',
        paddingBottom: '12px',
        marginTop: '12px',
        borderBottom: '1px solid',
        borderColor: 'neutral500',
        paddingLeft: '8px',
        paddingRight: '8px',
      })}
    >
      <Text size="s" sx={{ fontWeight: '400', color: 'gray600' }}>
        {title}
      </Text>
      <Text size="s" sx={{ fontWeight: '500', color: 'gray600' }}>
        ${costDollars.toFixed(2)}
      </Text>
    </div>
  );
};

export default SubtotalSection;
