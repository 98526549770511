import { SxProp, useDripsyTheme, useSx } from 'dripsy';
import React, { useRef, useEffect, useState } from 'react';
import { Text } from '../../typography/Text/Text';
import { IconCheck } from '../../icons';
import { useHover } from 'react-native-web-hooks';
import styles from './Checkbox.module.scss';

export interface CheckboxProps {
  onChange: (isChecked: boolean) => void;
  defaultChecked?: boolean;
  // if a parent component wants to control the state
  managedChecked?: boolean;
  label?: string;
  labelClassName?: string;
  disabled?: boolean;
}

// TODO: convert remaining inline styles to css
const Checkbox = ({
  onChange,
  defaultChecked,
  managedChecked,
  label,
  labelClassName,
  disabled,
}: CheckboxProps) => {
  const { theme } = useDripsyTheme();
  const checkboxSx = theme.checkbox;
  const sx = useSx();

  const [checked, setChecked] = useState(
    managedChecked !== undefined
      ? managedChecked
      : defaultChecked !== undefined
      ? defaultChecked
      : false
  );

  const handleChange = () => {
    const newCheck = !checked;
    setChecked(newCheck);
    onChange(newCheck);
  };

  useEffect(() => {
    if (managedChecked !== undefined) {
      setChecked(managedChecked);
    }
  }, [managedChecked]);

  const labelRef = useRef(null);
  const isHovered = useHover(labelRef);
  const renderHover = isHovered && !disabled;

  let inputStyle: SxProp = {};
  let iconColor: string = '';
  if (disabled) {
    inputStyle = {
      borderColor: 'neutral700',
      backgroundColor: 'neutral100',
    };
    iconColor = 'neutral700';
  } else {
    if (checked) {
      inputStyle = checkboxSx.checked;
    } else {
      inputStyle = checkboxSx.unchecked;
    }

    if (renderHover) {
      if (checked) {
        inputStyle = {
          ...inputStyle,
          ...checkboxSx.checked.$hover,
        };
      } else {
        inputStyle = {
          ...inputStyle,
          ...checkboxSx.unchecked.$hover,
        };
      }
    }
    iconColor = 'white';
  }
  const cursorStyle: SxProp = disabled
    ? { cursor: 'not-allowed' }
    : { cursor: 'pointer' };

  return (
    <label className={`${labelClassName} ${styles.container}`} ref={labelRef}>
      <div
        className={styles.checkboxContainer}
        style={sx(cursorStyle)}
        onTouchEnd={handleChange}
      >
        <input
          type="checkbox"
          onChange={handleChange}
          checked={checked}
          disabled={disabled}
          className={styles.checkbox}
          style={sx({
            ...cursorStyle,
            ...inputStyle,
            ...(renderHover
              ? {
                  boxShadow: '0 0 0 4px',
                }
              : undefined),
          })}
        />
        <div
          className={styles.checkIcon}
          style={{
            display: checked ? 'block' : 'none',
          }}
        >
          <IconCheck width={12} height={12} strokeWidth={4} color={iconColor} />
        </div>
      </div>
      {label ? <Text size="s">{label}</Text> : null}
    </label>
  );
};

export default Checkbox;
