import { AxiosInstance, AxiosResponse } from 'axios';
import { patch, post, del } from '../hooks/useAxiosPrivate';
import { genericFetch } from './api';
import { Auth } from '../state/auth/types';

import { DeviceType } from '../state/devices/types';

interface CreateDeviceBody {
  deviceType: DeviceType;
  serialNumber: string;
  pcbNumber: string;
  password: string;
  companyId: string;
}
const ARTYC_DEVICE_URL = '/artyc/devices';
const getCompanyDeviceUrl = (auth: Auth | null) =>
  `/api/v1/company/${auth?.companyId}/devices`;

const DevicesApi = {
  createDevice: async (axios: AxiosInstance, data: CreateDeviceBody) => {
    const response: AxiosResponse<{ success: string }> = await post(
      axios,
      ARTYC_DEVICE_URL,
      data
    );
    return response.data;
  },
  updateDevice: async (
    axios: AxiosInstance,
    deviceId: string,
    // TODO(ts): type
    data: any
  ) => {
    const response: AxiosResponse<{ success: string }> = await patch(
      axios,
      `${ARTYC_DEVICE_URL}/${deviceId}`,
      data
    );
    return response.data;
  },
  deleteDevice: async (axios: AxiosInstance, deviceId: string) => {
    const response: AxiosResponse<{ success: string }> = await del(
      axios,
      `${ARTYC_DEVICE_URL}/${deviceId}`
    );
    return response.data;
  },
  getDeviceTypes: async (axios: AxiosInstance, auth: Auth | null) => {
    const url = getCompanyDeviceUrl(auth);
    const response: { deviceTypes: DeviceType[] } = await genericFetch(
      axios,
      auth,
      `${ARTYC_DEVICE_URL}/types`,
      `${url}/types`
    );
    return response;
  },
};

export default DevicesApi;
