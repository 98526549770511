import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import {
  LoadDataAction,
  EntityState,
  DataPayload,
  SortPayloadAction,
} from '../types';
import { AxiosInstance } from 'axios';
import { CompanyEntity, CompanyFilters } from './types';
import CompaniesApi from '../../api/companiesApi';
import { Auth } from '../auth/types';

export interface CompanyState extends EntityState<CompanyEntity> {
  selectedFilters?: CompanyFilters;
}

const initialState: CompanyState = {
  data: [],
  currentPage: 0,
  // right now we return all results, so this isn't accurate...
  resultsPerPage: 8,
  totalPages: 0,
  sortColumn: 'companyName',
  sortDirection: -1,
  selectedFilters: {},
};

// right now we fetch all companies at once. will need to change when we have too many companies for one page
const companySlice = createSlice({
  name: 'companies',
  initialState,
  reducers: {
    setCompanies: (
      state,
      { payload }: PayloadAction<DataPayload<CompanyEntity>>
    ) => {
      state.data = payload.entities;
      state.totalPages = payload.totalPages;
    },
    sortCompanies: (state, { payload }: SortPayloadAction<CompanyEntity>) => {
      state.sortColumn = payload.column;
      state.sortDirection = payload.direction;
    },
    addCompany: (
      state,
      { payload }: PayloadAction<{ companyName: string; id: string }>
    ) => {
      state.data = [
        { companyName: payload.companyName, _id: payload.id },
        ...state.data,
      ];
    },
    setSearchFilter: (state, { payload }: PayloadAction<string>) => {
      state.selectedFilters = Object.assign({}, state.selectedFilters, {
        search: payload === '' ? undefined : payload,
      });
      state.currentPage = initialState.currentPage;
    },
    // doesn't really mean anything right now since we only have 1 page
    setCurrentPage: (state, { payload }: PayloadAction<number>) => {
      state.currentPage = payload;
    },
    resetPage: (state) => {
      state.selectedFilters = initialState.selectedFilters;
      state.sortColumn = initialState.sortColumn;
      state.sortDirection = initialState.sortDirection;
      state.currentPage = initialState.currentPage;
    },
  },
});

export const loadCompanies: LoadDataAction<CompanyEntity> = async (
  axios: AxiosInstance,
  auth: Auth,
  state: CompanyState
) => {
  const { sortColumn, sortDirection, selectedFilters } = state;

  const companyData = await CompaniesApi.getCompanies(axios);
  // sort/filter manually since endpoint just returns everything
  const sortedEntities = companyData.entities
    .sort(
      (a, b) =>
        a[sortColumn].toString().localeCompare(b[sortColumn].toString()) *
        sortDirection
    )
    .filter((company) => {
      const search = selectedFilters?.search;
      if (search === undefined) {
        return true;
      }
      return company.companyName.toLowerCase().includes(search.toLowerCase());
    });

  return setCompanies({
    entities: sortedEntities,
    totalPages: companyData.totalPages,
  });
};

export const {
  setCompanies,
  sortCompanies,
  addCompany,
  setSearchFilter,
  setCurrentPage,
  resetPage,
} = companySlice.actions;

export default companySlice.reducer;
