import React, { useState } from 'react';
import DataTableContainer from '../../components/table/DataTableContainer';
import {
  ColumnsConfig,
  EmptyConfig,
  TopConfig,
} from '../../components/table/tableConfig';
import PageHeader from '../../components/layout/PageHeader';
import { CustomerCostReportEntity } from '../../state/customerCostReports/types';
import {
  loadCustomerCostReports,
  resetPage,
  setCurrentPage,
  sortCustomerCostReports,
} from '../../state/customerCostReports';
import CreateCostButton from './components/CreateCostButton';
import { formatDateTime } from '../../utils/dateUtil';
import { useNavigate } from 'react-router-dom';
import {
  IconDownload,
  IconEdit,
  IconFileOff,
  IconFormCheck,
} from '../../aurora/icons';
import ActionButton from '../../components/table/ActionButton';
import IndustryEstimateLinks from './components/IndustryEstimateLinks';
import CostReport from './components/costCalculator/report/CostReport';
import { usePDF } from 'react-to-pdf';

const CustomerCostReportsPage = () => {
  const navigate = useNavigate();
  const [exportReport, setExportReport] =
    useState<CustomerCostReportEntity | null>(null);
  const { toPDF, targetRef } = usePDF();

  // temporarily render the component and export to PDF
  const onExport = (report: CustomerCostReportEntity) => {
    setExportReport(report);
    setTimeout(() => {
      const { current } = targetRef;
      if (current) {
        const companyName = report.companyName.replace(' ', '-');
        toPDF({ filename: `${companyName}_report.pdf` });
        setExportReport(null);
      }
    }, 200);
  };

  const topConfig: TopConfig = {
    text: 'Customer Cost Reports',
  };
  const emptyConfig: EmptyConfig = {
    icon: IconFileOff,
    title: 'No reports found',
    body: "Once reports are added, they'll show up here",
  };
  const columnsConfig: ColumnsConfig<CustomerCostReportEntity> = {
    columns: [
      {
        title: 'Company Name',
        property: 'companyName',
      },
      {
        title: 'Contact Name',
        property: 'contactFullName',
      },
      {
        title: 'Contact Email',
        property: 'contactEmail',
      },
      {
        title: 'Created At',
        property: 'createdAt',
        customData: (entity) => formatDateTime(entity.createdAt),
      },
      {
        title: 'Updated At',
        property: 'updatedAt',
        customData: (entity) => formatDateTime(entity.updatedAt),
      },
    ],
    actionColumn: (entity) => (
      <div style={{ display: 'flex', gap: '2px' }}>
        <ActionButton
          icon={IconEdit}
          onClick={() => {
            navigate(`/portal/cost-calculator/${entity._id}`);
          }}
          label="Edit estimate"
        />
        <ActionButton
          icon={IconFormCheck}
          onClick={() => {
            navigate(`/cost-report/${entity._id}`);
          }}
          label="View report"
        />
        <ActionButton
          icon={IconDownload}
          onClick={() => {
            onExport(entity);
          }}
          label="Export report"
        />
        {exportReport?._id === entity._id && (
          <div
            style={{ position: 'absolute', top: '-9999px', left: '-9999px' }}
          >
            <CostReport report={entity} printVersion ref={targetRef} />
          </div>
        )}
      </div>
    ),
  };

  return (
    <>
      <PageHeader
        headingText="Customer Cost Reports"
        action={<CreateCostButton />}
      />
      <DataTableContainer
        key="CustomerCostReports"
        topConfig={topConfig}
        emptyConfig={emptyConfig}
        columnsConfig={columnsConfig}
        stateName={'customerCostReports'}
        loadDataAction={loadCustomerCostReports}
        sortAction={sortCustomerCostReports}
        pageAction={setCurrentPage}
        resetAction={resetPage}
      />
      <IndustryEstimateLinks />
    </>
  );
};

export default CustomerCostReportsPage;
