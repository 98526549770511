import React from 'react';
import { Button } from '../../aurora/components/Button/Button';
import { Text } from '../../aurora/typography/Text/Text';
import { IconArrowRight, IconArrowLeft } from '../../aurora/icons';

import { SxProp, useSx } from 'dripsy';

import styles from './DataTablePagination.module.scss';

type PaginationProps = {
  currentPage: number;
  totalPages: number;
  onPage: (page: number) => void;
  sxProps?: SxProp;
};

const DataTablePagination: React.FC<PaginationProps> = ({
  currentPage,
  totalPages,
  onPage,
  sxProps,
}) => {
  const sx = useSx();

  const leftContent =
    currentPage > 0 ? (
      <Button
        variant="ghostSecondaryGray"
        label="Previous"
        iconLeft={IconArrowLeft}
        className={styles.tablePaginationPrev}
        onClick={() => onPage(currentPage - 1)}
      />
    ) : (
      <div className={styles.tablePaginationPrev}></div>
    );

  const rightContent =
    currentPage < totalPages - 1 ? (
      <Button
        variant="ghostSecondaryGray"
        label="Next"
        iconRight={IconArrowRight}
        className={styles.tablePaginationNext}
        onClick={() => onPage(currentPage + 1)}
      />
    ) : (
      <div className={styles.tablePaginationNext}></div>
    );

  return (
    <div className={styles.tablePagination} style={{ ...sx(sxProps ?? {}) }}>
      {leftContent}
      <Text className={styles.pages} size={'s'}>
        Page {currentPage + 1} of {totalPages}
      </Text>
      {rightContent}
    </div>
  );
};

export default DataTablePagination;
