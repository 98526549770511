import React from 'react';
import MobileFilter, {
  SlideoutContentProps,
} from '../../../aurora/components/MobileFilter/MobileFilter';
import ChecklistContent, { CheckedIdsSectionMap } from './ChecklistContent';
import { ChecklistSectionProp } from './ChecklistSection';

type MobileChecklistSection = {
  onApplyFilters: (selectedIds: string[]) => void;
  onClear: () => void;
} & ChecklistSectionProp;

interface Props {
  label?: string;
  sections: MobileChecklistSection[];
}

const MobileChecklistFilter = ({ sections, label }: Props) => {
  // don't show filter if all sections should be hidden
  const shouldShow = sections.reduce(
    (show, curr) => show || (curr.show !== undefined && curr.show),
    false
  );
  if (!shouldShow) {
    return null;
  }

  const emptyFilters: CheckedIdsSectionMap = {};
  const appliedFilters: CheckedIdsSectionMap = {};
  for (let i = 0; i < sections.length; i++) {
    const section = sections[i];
    appliedFilters[i] = section.selectedIds;
    emptyFilters[i] = [];
  }

  const handleSubmit = (filtersMap: CheckedIdsSectionMap) => {
    Object.keys(filtersMap).forEach((sectionIdxStr) => {
      const sectionIdx = parseInt(sectionIdxStr, 10);
      const section = sections[sectionIdx];
      if (section === undefined) {
        return;
      }
      const selectedIds = filtersMap[sectionIdx];

      section.onApplyFilters(selectedIds);
    });
  };
  const handleClear = () => {
    sections.forEach((section) => section.onClear());
  };

  const toFiltersSummary = (filtersMap: CheckedIdsSectionMap): string => {
    return Object.values(filtersMap)
      .reduce((sum, selectedIds) => sum + selectedIds.length, 0)
      .toString();
  };

  const content = ({
    onSetFilters,
    selectedFilters,
  }: SlideoutContentProps<CheckedIdsSectionMap>) => (
    <ChecklistContent
      onSetFilters={onSetFilters}
      selectedFilters={selectedFilters}
      sections={sections}
    />
  );

  return (
    <MobileFilter
      emptyFilters={emptyFilters}
      appliedFilters={appliedFilters}
      label={label || 'Filters'}
      slideoutContent={content}
      onApplyFilters={handleSubmit}
      onClear={handleClear}
      toFiltersSummary={toFiltersSummary}
    />
  );
};

export default MobileChecklistFilter;
