import React from 'react';
import { useDispatch } from 'react-redux';
import { Text } from '../../../../aurora/typography/Text/Text';
import { incrementLoadedGraph } from '../../../../state/shipments';
import { ShipmentEntity } from '../../../../state/shipments/types';
import { formatDateTime } from '../../../../utils/dateUtil';
import SnapshotsGraphContainer from './SnapshotsGraphContainer';

interface Props {
  shipments: ShipmentEntity[];
}
const GraphsPdf = ({ shipments }: Props) => {
  const dispatch = useDispatch();

  return (
    <>
      {shipments.map((shipment, idx) => (
        <div key={`graph-${idx}`}>
          <Text
            size="m"
            sx={{ color: 'gray600', marginLeft: '20px', marginTop: '20px' }}
          >
            {shipment.serialNumber} - {formatDateTime(shipment.startTime)}
          </Text>
          <SnapshotsGraphContainer
            shipments={[shipment]}
            onLoaded={() => {
              dispatch(incrementLoadedGraph());
            }}
            printVersion
          />
        </div>
      ))}
    </>
  );
};

export default GraphsPdf;
