import React, { useEffect } from 'react';
import Select from '../../../aurora/components/Select/Select';
import useAxiosPrivate from '../../../hooks/useAxiosPrivate';
import { useDispatch, useSelector } from 'react-redux';
import { isArtycAdmin, selectAuth } from '../../../state/auth';
import { RootState } from '../../../state/store';
import { loadCompanies } from '../../../state/companies';
import styles from './CompanySelect.module.scss';

interface Props {
  onSetCompanyId: (companyId: string | undefined) => void;
  selectedCompanyId: string | undefined;
  nullable?: boolean;
}
const CompanySelect = ({
  onSetCompanyId,
  selectedCompanyId,
  nullable,
}: Props) => {
  const dispatch = useDispatch();
  const axiosPrivate = useAxiosPrivate();
  const auth = useSelector(selectAuth);
  const companiesState = useSelector((state: RootState) => state.companies);
  const companies = companiesState.data;
  const isArtyc = isArtycAdmin(auth);

  const fetchCompanies = async () => {
    const action = await loadCompanies(axiosPrivate, auth!, companiesState);
    dispatch(action);
  };

  useEffect(() => {
    if (companies.length === 0 && isArtyc) {
      fetchCompanies();
    }
  }, []);

  useEffect(() => {
    if (!nullable && selectedCompanyId === undefined && companies.length > 0) {
      onSetCompanyId(companies[0]._id);
    }
  }, [companies]);

  if (!isArtyc) {
    return null;
  }

  const companyOptions = companies.map((company) => ({
    value: company._id,
    label: company.companyName,
  }));

  if (nullable) {
    companyOptions.unshift({ value: '', label: 'Select Company' });
  }

  const onChange = (newValue: string) => {
    if (newValue === '') {
      onSetCompanyId(undefined);
    } else {
      onSetCompanyId(newValue);
    }
  };

  return (
    <Select
      className={styles.companySelect}
      placeholder="Select Company"
      options={companyOptions}
      onChange={(option) => onChange(option.value)}
      managedValue={selectedCompanyId}
      variant="filter"
    />
  );
};

export default CompanySelect;
