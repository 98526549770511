import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import axios from '../../api/axios';
import jwt_decode from 'jwt-decode';
import { setAuth } from '../../state/auth';
import {
  setSelectedReport,
  setFormInputs,
} from '../../state/customerCostReports';
import CustomerCostReportApi from '../../api/customerCostReportApi';
import { reportToFormInputs } from '../../state/customerCostReports/utils/reportToFormInputs';
import { Text } from '../../aurora/typography/Text/Text';
import { RootState } from '../../state/store';
import CostReport from './components/costCalculator/report/CostReport';

const CostReportPage = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  const { selectedReport } = useSelector(
    (state: RootState) => state.customerCostReports
  );
  // pull credentials if there are any, but don't redirect to login if there aren't
  const refreshToken = async () => {
    try {
      const response = await axios.get('/auth/refresh', {
        withCredentials: true,
      });

      const { accessToken } = response.data;
      const { userInfo } = jwt_decode<any>(accessToken);

      dispatch(setAuth({ ...userInfo, accessToken }));
    } catch (e: any) {
      console.log('not logged in');
    }
  };

  useEffect(() => {
    refreshToken();
  }, []);

  useEffect(() => {
    const fetchReport = async () => {
      if (id) {
        setLoading(true);

        try {
          const currentReport =
            await CustomerCostReportApi.getOpenCustomerCostReport(axios, id);
          dispatch(setSelectedReport(currentReport));
          dispatch(setFormInputs(reportToFormInputs(currentReport)));
        } catch (error) {
          console.error('Error fetching report:', error);
        } finally {
          setLoading(false);
        }
      }
    };

    fetchReport();

    return () => {
      dispatch(setSelectedReport(null));
    };
  }, [id]);

  if (loading) {
    return (
      <Text size="m" sx={{ margin: '12px' }}>
        Loading...
      </Text>
    );
  }

  if (id === undefined || selectedReport === null) {
    return <div>Unexpected Error</div>;
  }

  return <CostReport report={selectedReport} printVersion={false} />;
};

export default CostReportPage;
