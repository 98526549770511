import React from 'react';
import { ShipmentModalTypeEnum } from '../../../state/shipments';
import { ShipmentEntity } from '../../../state/shipments/types';
import DataModal from '../../../components/DataModal';
import SnapshotsGraphContainer from './graph/SnapshotsGraphContainer';
import SnapshotsMapContainer from './map/SnapshotsMapContainer';

interface Props {
  modalType: ShipmentModalTypeEnum;
  modalShipments: ShipmentEntity[];
  onClose: () => void;
}

const ShipmentPageModal = ({ modalType, modalShipments, onClose }: Props) => {
  const modalTitle =
    modalShipments.length === 1
      ? `${modalShipments[0].deviceType} #${modalShipments[0].serialNumber} - ${modalShipments[0].pid}`
      : undefined;

  return (
    <DataModal
      title={modalTitle}
      show={modalShipments.length > 0}
      width="1200px"
      onClose={onClose}
    >
      {modalShipments.length > 0 &&
        (modalType === 'graph' ? (
          <SnapshotsGraphContainer shipments={modalShipments} />
        ) : (
          <SnapshotsMapContainer shipments={modalShipments} />
        ))}
    </DataModal>
  );
};

export default ShipmentPageModal;
