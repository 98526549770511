import React from 'react';
import { Path } from 'react-native-svg';

import { IconBase, IconProps } from './IconBase';

export const IconShield: React.FunctionComponent<IconProps> = ({
  ...props
}) => (
  <IconBase {...props} viewBox="0 0 16 16">
    <Path
      d="M7.1647 14.8255C7.32129 14.9168 7.39959 14.9625 7.51008 14.9862C7.59584 15.0046 7.72094 15.0046 7.8067 14.9862C7.91719 14.9625 7.99549 14.9168 8.15208 14.8255C9.52993 14.0217 13.3168 11.4966 13.3168 8.02489V4.64231C13.3168 4.07681 13.3168 3.79407 13.2243 3.55102C13.1426 3.33631 13.0098 3.14473 12.8375 2.99284C12.6424 2.8209 12.3776 2.72162 11.8481 2.52306L8.05575 1.10091C7.9087 1.04577 7.83518 1.0182 7.75954 1.00727C7.69246 0.997576 7.62432 0.997576 7.55723 1.00727C7.4816 1.0182 7.40808 1.04577 7.26103 1.10091L3.46864 2.52306C2.93915 2.72162 2.67441 2.8209 2.47931 2.99284C2.30696 3.14473 2.17419 3.33631 2.09249 3.55102C2 3.79407 2 4.07681 2 4.64231V8.02489C2 11.4966 5.78685 14.0217 7.1647 14.8255Z"
      stroke="currentColor"
      strokeWidth="1.33"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </IconBase>
);
