import React from 'react';
import { useDispatch } from 'react-redux';
import { setSearchFilter } from '../../../state/shipments';
import TableSearch from '../../../aurora/components/TableSearch/TableSearch';

const ShipmentSearch = () => {
  const dispatch = useDispatch();

  return (
    <TableSearch
      placeholder="Search by serial #, shipment ID"
      onChange={(text) => {
        dispatch(setSearchFilter(text));
      }}
    />
  );
};

export default ShipmentSearch;
